import { Injectable, Inject } from '@angular/core';
import { CommunicationStore, COMMUNICATION_STORE } from './communication.store';
import { Message } from '../models/message.model';
import { GroupDetails } from '../models/group-details.model';

@Injectable({
    providedIn: 'root',
})
export class CommunicationRepository {
    constructor(
        @Inject(COMMUNICATION_STORE) private communicationStore: CommunicationStore,
    ) { }


    getValue() {
        return this.communicationStore.value;
    }

    updateSelectedGroup(selectedGroup: GroupDetails): void {
        let messages = selectedGroup.messages?.length > 0 ? [...selectedGroup.messages] : [];
        if (this.getValue().selectedGroup?.id === selectedGroup.id) {
            if (this.getValue().selectedGroup.messages?.length > 0)
                messages = [...messages, ...this.getValue().selectedGroup.messages];
        }
        this.communicationStore.update((state) => ({
            ...state,
            selectedGroup: { ...selectedGroup, messages },
        }));
    }

    addMessageToGroup(message: Message): void {
        const selectedGroup = this.getValue().selectedGroup;

        if (selectedGroup && selectedGroup.id === message.groupId) {
            this.communicationStore.update((state) => ({
                ...state,
                selectedGroup: { ...selectedGroup, messages: [...selectedGroup.messages, message] },
            }));
        }
        if (!this.getValue().isChatDialogOpened) {
            this.updateNumberOfUnreadMessages();
        }
    }

    markChatAsRead(groupId: string): void {
        const selectedGroup = this.getValue().selectedGroup;
        if (selectedGroup && selectedGroup.id === groupId) {
            this.communicationStore.update((state) => ({
                ...state,
                selectedGroup: { ...selectedGroup, messages: selectedGroup.messages.map(e => ({ ...e, isRead: true })) }
            }));
        }
    }

    updateIsChatDialogOpened(isChatDialogOpened: boolean): void {
        this.communicationStore.update((state) => ({
            ...state,
            isChatDialogOpened
        }));
    }

    updateNumberOfUnreadMessages(): void {
        this.communicationStore.update((state) => ({
            ...state,
            numberOfUnreadMessages: state.numberOfUnreadMessages + 1
        }));
    }

    resetNumberOfUnreadMessages(): void {
        this.communicationStore.update((state) => ({
            ...state,
            numberOfUnreadMessages: 0
        }));
    }
}
