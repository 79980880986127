import { InjectionToken } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { OutputPropertyItemModel } from '@root/shared/models/output-keys.model';

export interface OutputKeysStoreModel {
  outputKeys: OutputPropertyItemModel;
}

const store = createStore(
  {
    name: 'output-keys-store',
  },
  withProps<OutputKeysStoreModel>({
    outputKeys: null,
  })
);

persistState(store, {
  storage: sessionStorageStrategy,
});


export const outputKeys$ = store.pipe(select(({ outputKeys }) => outputKeys));

export type OutputKeysStore = typeof store;
export const OUTPUT_KEYS_STORE = new InjectionToken<
  ReturnType<typeof createStore>
>('Injection Token For Output Keys Store', {
  providedIn: 'root',
  factory: (): OutputKeysStore => store,
});