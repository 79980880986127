<div class="absolute p-4 w-full bg-transparent z-50">
    <div [@notificationAnimation]
        class="flex relative items-center px-4 py-3 bg-white border-black border shadow rounded-lg ">
        <div class="flex flex-col justify-center h-full w-5/6 ">
            <span class="text-base font-bold" translate>notification.thank-you-for-submitting</span>
            <span class="text-sm" translate>notification.please-download-your-file</span>
        </div>
        <div class="flex justify-center items-center h-full w-1/6 ">
            <mat-spinner class="download-file-spinner "></mat-spinner>
        </div>
    </div>
</div>