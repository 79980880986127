import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TensorFlowPredictionsService } from "@root/shared/components/basic-detection/services/tensorflow-predictions.service";

@Injectable({
    providedIn: 'root'
})
export class CacheStorageService {

    loadOpenCvFileSubject = new BehaviorSubject<boolean>(false);
    loadOpenCvFile$ = this.loadOpenCvFileSubject.asObservable();
    constructor(
        private http: HttpClient,
        private tensorFlowPredictionsService: TensorFlowPredictionsService
    ) { }

    async cacheData(key: string, data: any) {
        const cache = await caches.open('my-cache-name');
        const response = new Response(JSON.stringify(data));
        await cache.put(key, response)
    }

    async getCachedData(key: string) {
        const cache = await caches.open('my-cache-name');
        const response = await cache.match(key);
        const jsonData = await response.json();
        return jsonData;
    }

    async downloadAndCacheFile(url: string) {
        this.http.get(url, {
            responseType: 'blob' as 'json', headers: new HttpHeaders({
                InterceptorSkipConfigurationHeader: ''
            })
        }).subscribe(async (data: any) => {
            const cache = await caches.open('js-cache');
            await cache.put(url, new Response(data));
            this.loadOpenCvFileSubject.next(true);
        });
    }

    async getFileFromCache(url: string) {
        const cache = await caches.open('js-cache');
        const response = await cache.match(url);
        if (response) {
            return response.blob();
        }
        return null;
    }

    async savePredictionModel() {
        await this.tensorFlowPredictionsService.saveModel();
    }

}