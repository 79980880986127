import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'app-image-preview',
  standalone: true,
  imports: [MatDialogModule, MatIconModule],
  templateUrl: './image-preview.component.html',
  styleUrl: './image-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePreviewComponent extends BaseComponent implements OnInit {
  imageSrc: string;
  thumbImage: string;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: string,
    private dialog: MatDialog) { super(); }

  ngOnInit(): void {
    this.imageSrc = this.dialogData;
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.dialog.closeAll();
    }
  }

}
