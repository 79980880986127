import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppRepository } from '@root/store/app.repository';
import { ThemeService } from '@root/shared/services/theme-service/theme.service';
import { environment } from 'src/environments/environment';
import { Theme } from '@root/shared/models/theme.model';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  constructor(private http: HttpClient,
    private themeService: ThemeService,
    private appRepository: AppRepository,
  ) { }

  /**
   * update angular material default theme (Assentify theme)
   */
  setDefaultTheme() {
    this.themeService.setDefaultTheme();
  }

  getTheme(): Theme {
    return this.appRepository.getTheme();
  }

  /**
   * update angular material theme, and save App Configuration
   * @param Landing model
   * @returns {void}
   */
  async getTenantTheme(tenantIdentifier: string) {
    // get theme from landing store
    const theme = this.appRepository.getTheme();
    if (theme) {
      // update angular theme
      this.themeService.updateTheme(theme);
    } else {
      // get Theme from TenantTheme API
      await this.http.get<Theme>(`${environment.assentifyEndPoint}TenantTheme/GetTenantTheme/${tenantIdentifier}`)
        .subscribe({
          error: () => {
            //TO DO open error pop-up
          },
          next: (theme: Theme) => {
            if (theme) {
              // update angular material theme.
              this.themeService.updateTheme(theme);
              // save theme in 'app.0' store.
              this.appRepository.updateTheme(theme);
            }
          },
          complete: () => {
            // Redirect to blockLoader page.
          },
        });
    }
  }



}
