import { Inject, Injectable } from "@angular/core";
import { BASIC_DETECTION_STORE, BasicDetectionStore } from "./basic.detection.store";
import { QualityProcessingMessageModel } from "../model/quality-processing-message.model";
import { ManualCaptureMessage } from "../model/manual-capture-message.model";
import { TurnOnCameraModel } from "../model/turn-on-camera.model";
import { ResultAnimationModel } from "../model/result-animation.model";
import { ResultCompletedModel } from "../model/result-completed,model";
import { SuccessImageModel } from "../model/success-image.model";
import { FrontAndBackLabelStatusModel } from "../model/front-and-back-label-status.model";
import { InitializingModel } from "../model/initializing.model";
import { LabelModel } from "../model/label.model";
import { IdCardRotationModel } from "../model/id-card-rotation.model";
import { SignalRConnectionFailedModel } from "../model/signalr-connection-failed.model";
import { OpenCameraFailedModel } from "../model/open-camera-failed.model";
import { ProcessingModel } from "../model/processing.model";
import { RetryMessageModel } from "../model/retry-message.model";
import { IdCardGuideModel } from "../model/id-card-guide.model";
import { ExpiredPassportModel } from "../model/expired-passport.model";
import { WrongTemplateModel } from "../model/wrong-template.model";
import { HoldSteadyModel } from "../model/hold-steady-model";
import { CardScanGuidance } from "../model/card-scan-guidance.model";
import { RealIdModel } from "../model/real-id.model";
import { MisMatchModel } from "../model/mismatch";
import { CameraPermissionFailedModel } from "../model/camera-permission-failed.model";
import { GettingThingsReadyModel } from "../model/getting-things-ready.model";
import { AutoCaptureOffModel } from "../model/auto-capture-off.model";
import { BlurredImageModel } from "../model/blurred-image.model";


@Injectable({
    providedIn: 'root',
})
export class BasicDetectionRepository {

    constructor(@Inject(BASIC_DETECTION_STORE) private store: BasicDetectionStore) { }

    getValue() {
        return this.store.getValue();
    }

    updateHoldSteadyModel(holdSteadyModel: HoldSteadyModel) {
        this.store.update((state) => ({
            ...state,
            holdSteadyModel,
        }));
    }

    updateCardScanGuidance(cardScanGuidance: CardScanGuidance) {
        this.store.update((state) => ({
            ...state,
            cardScanGuidance,
        }));
    }

    updateColorBoundingBox(colorBoundingBox: string) {
        this.store.update((state) => ({
            ...state,
            colorBoundingBox,
        }));
    }

    getColorBoundingBox(): string {
        return this.store.getValue().colorBoundingBox;
    }

    updateOnRetryMessage(retryMessageModel: RetryMessageModel) {
        this.store.update((state) => ({
            ...state,
            retryMessageModel,
        }));
    }

    updateIdCardGuideModel(idCardGuideModel: IdCardGuideModel) {
        this.store.update((state) => ({
            ...state,
            idCardGuideModel,
        }));
    }

    updateProcessingModel(processingModel: ProcessingModel) {
        this.store.update((state) => ({
            ...state,
            processingModel,
        }));
    }

    updateQualityProcessingMessage(qualityProcessingMessage: QualityProcessingMessageModel) {
        this.store.update((state) => ({
            ...state,
            qualityProcessingMessage,
        }));
    }

    updateAutoCaptureOffModel(autoCaptureOffModel: AutoCaptureOffModel) {
        this.store.update((state) => ({
            ...state,
            autoCaptureOffModel,
        }));
    }

    updateProcessQualityCheck(processQualityCheck: boolean) {
        this.store.update((state) => ({
            ...state,
            processQualityCheck
        }));
    }

    updateManualCaptureMessage(manualCaptureMessage: ManualCaptureMessage) {
        this.store.update((state) => ({
            ...state,
            manualCaptureMessage,
        }));
    }

    updateTurnOnCamera(turnOnCameraModel: TurnOnCameraModel) {
        this.store.update((state) => ({
            ...state,
            turnOnCameraModel,
        }));
    }


    updateHasPredictions(hasPredictions: boolean) {
        this.store.update((state) => ({
            ...state,
            hasPredictions,
        }));
    }


    updateGettingThingsReadyModel(gettingThingsReadyModel: GettingThingsReadyModel) {
        this.store.update((state) => ({
            ...state,
            gettingThingsReadyModel,
        }));
    }

    updateResultAnimation(resultAnimationModel: ResultAnimationModel) {
        this.store.update((state) => ({
            ...state,
            resultAnimationModel,
        }));
    }

    updateSuccessImage(successImageModel: SuccessImageModel) {
        this.store.update((state) => ({
            ...state,
            successImageModel,
        }));
    }

    updateResultCompleted(resultCompletedModel: ResultCompletedModel) {
        this.store.update((state) => ({
            ...state,
            resultCompletedModel,
        }));
    }

    reset() {
        this.store.reset();
    }

    updateInitializingModel(initializingModel: InitializingModel) {
        this.store.update((state) => ({
            ...state,
            initializingModel,
        }));
    }

    updateLabelModel(labelModel: LabelModel) {
        this.store.update((state) => ({
            ...state,
            labelModel,
        }));
    }

    updateFrontAndBackLabelStatusModel(frontAndBackLabelStatusModel: FrontAndBackLabelStatusModel) {
        this.store.update((state) => ({
            ...state,
            frontAndBackLabelStatusModel,
        }));
    }

    updateIdCardRotationModel(idCardRotationModel: IdCardRotationModel) {
        this.store.update((state) => ({
            ...state,
            idCardRotationModel,
        }));
    }

    updateSignalRConnectionFailedModel(signalRConnectionFailedModel: SignalRConnectionFailedModel) {
        this.store.update((state) => ({
            ...state,
            signalRConnectionFailedModel,
        }));
    }

    updateOpenCameraFailedModel(openCameraFailedModel: OpenCameraFailedModel) {
        this.store.update((state) => ({
            ...state,
            openCameraFailedModel,
        }));
    }

    updateCameraPermissionFailedModel(cameraPermissionFailedModel: CameraPermissionFailedModel) {
        this.store.update((state) => ({
            ...state,
            cameraPermissionFailedModel,
        }));
    }

    updateInfoText(infoText: string) {
        this.store.update((state) => ({
            ...state,
            infoText
        }));
    }

    updateBlurredImageModel(blurredImageModel: BlurredImageModel) {
        this.store.update((state) => ({
            ...state,
            blurredImageModel
        }));
    }

    updateExpiredPassportModel(expiredPassportModel: ExpiredPassportModel) {
        this.store.update((state) => ({
            ...state,
            expiredPassportModel,
        }));
    }

    updateWrongTemplateModel(wrongTemplateModel: WrongTemplateModel) {
        this.store.update((state) => ({
            ...state,
            wrongTemplateModel,
        }));
    }

    updateRealID(realId: RealIdModel) {
        this.store.update((state) => ({
            ...state,
            realId,
        }));
    }

    updateMisMatchModel(misMatchModel: MisMatchModel) {
        this.store.update((state) => ({
            ...state,
            misMatchModel,
        }));
    }
}
