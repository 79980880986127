import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FooterState } from '@root/layout/models/footer-state.model';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { UIService } from '@root/shared/services/ui-service/ui-service.service';
import { showSkeleton$, stepValidation$ } from '@root/store/app.store';
import { LayoutService } from '@root/layout/services/layout.service';
import { TranslateModule } from '@ngx-translate/core';
import { RaisedButtonComponent } from '@root/shared/components/raised-button/raisedButton.component';
import { NgClass, NgStyle, NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    RaisedButtonComponent,
    NgStyle,
    TranslateModule,
    NgTemplateOutlet,
    AsyncPipe,
  ],
})
export class FooterComponent extends BaseComponent implements OnInit {
  @Input() footerState: FooterState | undefined;
  @Input() showFooter = true;

  @Output() footerEventEmitter: EventEmitter<void> = new EventEmitter();
  isMobile$ = this.uIService.isMobile$;
  basicState = 0;
  skipValidation: boolean;
  showSkeleton = false;


  constructor(private uIService: UIService,
    private layoutService: LayoutService) {
    super();
  }

  get template() {
    return this.layoutService.getFooterDynamicComponent();
  }



  ngOnInit(): void {
    this.subscriptions.add(
      stepValidation$.subscribe((skipValidation: boolean) => {
        this.skipValidation = skipValidation;
      })
    );

    this.subscriptions.add(
      showSkeleton$.subscribe((data: boolean) => {
        this.showSkeleton = data;
      })
    );
  }

  completeEvent() {
    if (this.skipValidation) {
      this.footerEventEmitter.emit();
    }
  }

  skipEvent() {
    if (this.skipValidation) {
      this.footerEventEmitter.emit();
    }
  }

}
