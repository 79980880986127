import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { KeyResponseModel } from './models/key.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataEncryptionService {
  constructor(private httpClient: HttpClient) {}

  // encryptData(data: string, key: string): string {
  //   const iv = CryptoJS.lib.WordArray.random(16);
  //   const encryptionKey = CryptoJS.enc.Hex.parse(key);
  //   const encrypted = CryptoJS.AES.encrypt(data, encryptionKey, { iv: iv });
  //   const encryptedDataWithIv = iv
  //     .concat(encrypted.ciphertext)
  //     .toString(CryptoJS.enc.Base64);
  //   return encryptedDataWithIv;
  // }

  getKey(): Observable<string> {
    const endPointUrl = `${environment.assentifyEndPoint}/Compliance`;
    return this.httpClient
      .get(endPointUrl)
      .pipe(
        map((response: KeyResponseModel) => response.assentifyComplianceKey)
      );
  }

  encryptData(msg: string, code: string): string {
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const key = CryptoJS.enc.Hex.parse(code);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const encrypted = CryptoJS.AES.encrypt(msg, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    const s = salt.toString(CryptoJS.enc.Hex);
    const i = iv.toString(CryptoJS.enc.Hex);
    const e = encrypted.toString(CryptoJS.format.Hex);
    const transitmessage = s+i+e;
    return transitmessage;
}
}
