import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MessageType } from '../../models/enums/message-type';
import { selectedGroup$ } from '../../store/communication.store';
import { ChatService } from '../../services/chat.service';
import { GroupDetails } from '../../models/group-details.model';
import { CommunicationRepository } from '../../store/communication.repository';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import moment from 'moment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent extends BaseComponent implements OnInit {
  @ViewChild('selectedConversation', { static: false }) selectedConversation: ElementRef;
  selectedGroup: GroupDetails;
  messageType = MessageType;
  pageIndex = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private communicationRepository: CommunicationRepository,
    private chatService: ChatService) {
    super();
  }
  ngOnInit(): void {

    this.communicationRepository.updateSelectedGroup({} as GroupDetails);
    this.subscriptions.add(selectedGroup$.subscribe(data => {
      if (!this.isEmpty(data)) {
        this.selectedGroup = {
          ...data,
          messages: data.messages.map(e => ({
            ...e,
            createdAt: this.getMessageTime(e.createdAt)
          }))
        }
        setTimeout(() => {
          this.scrollToBottom();
        }, 200);
      } else {
        this.selectedGroup = null;
      }
      this.cdr.detectChanges();
    }));
  }

  onScrollUp(event: any) {
    if (event.currentScrollPosition === 0) {
      this.pageIndex++;
      this.chatService.getGroupDetails(this.selectedGroup.id, this.pageIndex);
    }
  }

  scrollToBottom() {
    if (this.selectedConversation) {
      const element = this.selectedConversation.nativeElement;
      this.renderer.setProperty(element, 'scrollTop', element.scrollHeight);
      this.cdr.detectChanges();
    }
  }

  getMessageTime(createdAt: string) {
    const stillUtc = moment.utc(createdAt).toDate();
    return moment(stillUtc).local().format('HH:mm');
  }

  onCloseChat() {
    this.communicationRepository.updateIsChatDialogOpened(false);
  }

}