import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Lang } from '../models/enums/languages.enum';
import { PageDirection } from '../models/enums/page-direction.enum';
import { SharedRepository } from '../store/shared/shared.repository';

@Injectable({ providedIn: 'root' })
export class ChangeLanguageService {
    lang = Lang;

    constructor(
        public translate: TranslateService,
        @Inject(DOCUMENT) private document: Document,
        private titleService: Title,
        private translateService: TranslateService,
        private sharedRepository: SharedRepository
    ) { }

    async changeLang(lang: Lang, appTitle: string= 'Assentify') {
        try {
            localStorage.setItem('language', lang === this.lang.Arabic ? Lang.Arabic : Lang.English);
            await this.translate.use(lang);
            const pageDirection = lang === this.lang.Arabic ? PageDirection.RTL : PageDirection.LTR;
            this.document.body.dir = pageDirection;
            this.sharedRepository.updatePageDirection(pageDirection);
            this.titleService.setTitle(this.translateService.instant(appTitle));
        } catch (error) {
            console.error('Error in changeLang', error);
        }
    }
}
