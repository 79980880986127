import { AppRepository } from '@root/store/app.repository';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { CommunicationRepository } from '../store/communication.repository';
import { Message } from '../models/message.model';
import { environment } from 'src/environments/environment';
import { log } from '@root/shared/services/utilities.service/utilities.service';


@Injectable({
    providedIn: 'root'
})
export class SignalrService {
    private readonly baseUrl = environment.chatSignalrUrl;
    private options: signalR.IHttpConnectionOptions;
    private hubConnection: signalR.HubConnection;

    constructor(private communicationRepository: CommunicationRepository,
        private appRepository: AppRepository
    ) {
        this.options = {
            transport: signalR.HttpTransportType.LongPolling,
            withCredentials: true
        };


        this.createConnection(this.appRepository.getAppConfiguration()?.instanceId);

        this.addOnReceiveMessageListener();

        this.onReconnected();

    }

    connected(): boolean {
        return this.hubConnection.state === signalR.HubConnectionState.Connected;
    }


    public createConnection = (interactionId: string) => {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${this.baseUrl}/chatHub?interactionId=${interactionId}`, this.options)
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();
    };

    public startConnection = () => {
        if (!this.connected())
            this.hubConnection
                .start()
                .then(() => log('Connection started'))
                .catch(error => {
                    log('Error while establishing connection', error);
                });

    };

    public onReconnected = () => {
        this.hubConnection.onreconnected(() => {
            log('on reconnect')
        });
    };

    public addOnReceiveMessageListener = () => {
        this.hubConnection.on('ReceiveMessage', (message: Message) => {
            this.communicationRepository.addMessageToGroup(message);
            if (this.communicationRepository.getValue().selectedGroup.id === message.groupId) {
                this.hubConnection.invoke("MarkMessageAsRead", message.id, this.appRepository.getAppConfiguration().instanceId,
                    this.appRepository.getAppConfiguration().tenantId)
                    .catch(err => console.error(err));
            }
        });

        this.hubConnection.on('MarkMessageAsRead', (groupId: string) => {
            log(groupId);
            this.communicationRepository.markChatAsRead(groupId);
        });
    };

    public stopConnection = () => {
        this.hubConnection
            .stop()
            .then(() => log('Connection stopped'))
            .catch(err => log('Error while stopping connection: ' + err));
    };
}
