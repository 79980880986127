import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApplicationRoutes } from '@root/shared/settings/common.settings';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { combineLatestWith, filter, take } from 'rxjs';
import { AppService } from '@root/shared/services/app.service';
import { appConfiguration$, customProperties$, theme$ } from '@root/store/app.store';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OutputKeysRepository } from '@root/shared/store/output-keys/output-keys.repository';
import { OutputPropertyItemModel } from '@root/shared/models/output-keys.model';
import { TranslateModule } from '@ngx-translate/core';
import { AppRepository } from '@root/store/app.repository';
import { AppConfiguration } from '@root/shared/models/app-configuration.model';
import { logoFadeInAnimations } from '../../animations/animations';
import { FilesCachingService } from '@root/shared/services/files-caching-service/files-caching.service';
import { cachedFiles$ } from '@root/shared/services/files-caching-service/store/files-caching.store';
import { CachedFile } from '@root/shared/services/files-caching-service/model/cached-file.model';
import { Theme } from '@root/shared/models/theme.model';
import { LandingRepository } from '../../store/landing.repository';
import { BlockLoaderService } from '../../services/block-loader.service';
import { blockLoader$ } from '../../store/landing.store';
import { BlockLoaderModel } from '../../models/block-loader.model';
import { CacheStorageService } from '@root/shared/services/cache-storage.service';
import { DataSourceRepository } from '@root/shared/services/data-source/data-source.repository';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [logoFadeInAnimations],
  imports: [MatProgressSpinnerModule, TranslateModule],
})
export class LandingComponent extends BaseComponent implements OnInit {
  params: Params;
  outputKeys: OutputPropertyItemModel = {};
  appConfiguration: AppConfiguration;
  @ViewChild('logo', { static: false })
  logo!: ElementRef;
  logoFadeIn = false;
  blockLoaderData: BlockLoaderModel;
  theme: Theme;

  constructor(private blockLoaderService: BlockLoaderService,
    private appService: AppService,
    private appRepository: AppRepository,
    private dataSourceRepository: DataSourceRepository,
    private outputKeysRepository: OutputKeysRepository,
    private blockLoaderRepository: LandingRepository,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private cdr: ChangeDetectorRef,
    private cacheStorageService: CacheStorageService,
    private filesCachingService: FilesCachingService
  ) {
    super();
    history.pushState(null, null, window.location.href);
  }

  get getBorder() {
    return this.theme
      ? `border: 2px solid ${this.theme.accentColor}`
      : ' border: 2px solid #d8d8d8';
  }

  ngOnInit() {
    sessionStorage.clear();
    this.blockLoaderRepository.updateBlockLoader(undefined);
    this.appRepository.reset();
    this.dataSourceRepository.reset();
    this.filesCachingService.clearCashURLs();

    this.subscriptions.add(
      appConfiguration$.subscribe((appConfiguration: AppConfiguration) => {
        if (appConfiguration) {
          this.appRepository.updateOnLanding({
            isOnLanding: true,
            instanceHash: appConfiguration.instanceHash,
          });
        }
      })
    );

    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
        this.params = queryParams;
        const { flowInstanceId, interaction } = queryParams;
        if (interaction) {
          // Action 1, Call Start API
          this.appService.callStartApi(interaction, flowInstanceId);
        } else {
          this.route.navigate([ApplicationRoutes.AreYouLost]);
        }
      })
    );


    this.subscriptions.add(
      appConfiguration$
        .pipe(combineLatestWith(customProperties$))
        .pipe(
          filter((value) =>
            value.every((item) => item !== undefined && item !== null)
          ),
          take(1)
        )
        .subscribe(([appConfiguration, customProperties]) => {
          if (appConfiguration && customProperties) {
            this.appConfiguration = appConfiguration;
            // Action 2, Add outputProperties
            Object.entries(customProperties).forEach(([key, value]) => {
              this.outputKeysRepository.addOutputProperty(
                key,
                value.toString()
              );
            });
          }
        })
    );

    this.subscriptions.add(
      theme$.subscribe((theme: Theme) => {
        if (theme) {
          this.theme = theme;
        }

        if (theme?.logoAccent && theme?.logo) {
          // Action 5, Cache logo and logoAccent
          this.filesCachingService.cacheUrls = [theme.logoIcon];
        }
      })
    );

    this.subscriptions.add(
      cachedFiles$
        .pipe(combineLatestWith(theme$))
        .pipe(
          filter((value) =>
            value.every((item) => item !== undefined && item !== null)
          )
        )
        .subscribe(async ([cachedFiles, theme]) => {
          const storedCachedFile = cachedFiles as CachedFile[];
          if (storedCachedFile?.length === 1) {
            // Action 6, Update logo
            const storedTheme = theme as Theme;
            const logoIndex = storedCachedFile?.findIndex(
              (file) => file.url === storedTheme.logoIcon
            );
            if (logoIndex > -1) {
              this.logo.nativeElement.src =
                storedCachedFile[logoIndex].objectUrl;
              this.logoFadeIn = true;
            }
            // Action 7, Call Block Loader API
            this.blockLoaderService.callBlockLoaderAPI();
            this.cdr.detectChanges();
          }
        })
    );

    this.subscriptions.add(
      blockLoader$.subscribe((data: BlockLoaderModel) => {
        if (data) {
          this.blockLoaderData = data;
          // Action 8, Deal with blockLoader data
          // this.outputKeysRepository.deleteOutputPropertyByStepDefinition(
          //   data.stepId
          // );
          this.appService.updateStepIdAndStepDefinitionInAppConfiguration(
            data.blockStepId,
            data.stepTypeDto.definition
          );
          this.blockLoaderService.updateBlockLoaderDefaultOutputKeys(
            data,
            this.appConfiguration
          );
          this.dataSourceRepository.updateDataSourceValues(
            data.dataSourceValues
          );
          // Action 9, Save Prediction Model
          this.cacheStorageService.savePredictionModel();
          // Action 10, get Next Step
          this.appRepository.updateOnLanding({
            isOnLanding: false,
            instanceHash: null,
          });
          this.appService.getNextStep();
        }
      })
    );
  }
}
