import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-validation-message',
    templateUrl: './validation-error-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatFormFieldModule],
})
export class ValidationMessageComponent {
  textMessage = '';
  hide = true;

  constructor(private cdr: ChangeDetectorRef) {}

  @Input()
  set text(value: string) {
    if (this.textMessage !== value) {
      this.textMessage = value;
      this.hide = false;
      this.cdr.detectChanges();
    }
  }
}
