import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  disableStartProcessingButtonSubject = new BehaviorSubject<boolean>(false);
  disableStartProcessingButton$ = this.disableStartProcessingButtonSubject.asObservable();

  private footerDynamicTemplate: TemplateRef<any>;


  loadFooterDynamicComponent(template: TemplateRef<any>): void {
    this.footerDynamicTemplate = template;
  }

  getFooterDynamicComponent() {
    return this.footerDynamicTemplate;
  }

}
