import { Component, ChangeDetectionStrategy, Inject, HostListener, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogData } from '../../models/dialog-confirmation-data.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, NgClass } from '@angular/common';
import { pageDirection$ } from '@root/shared/store/shared/shared.store';
import { PageDirection } from '@root/shared/models/enums/page-direction.enum';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-dialog-confirmation',
    templateUrl: './dialog-confirmation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        NgClass,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        CommonModule
    ],
})
export class ConfirmationDialogComponent implements OnInit {
    pageDirection$: Observable<PageDirection>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
        private mdDialogRef: MatDialogRef<ConfirmationDialogComponent>
    ) { }

    @HostListener('keydown.esc')
    public onEsc() {
        this.close(false);
    }
    public close(value: boolean) {
        this.mdDialogRef.close(value);
    }
    public confirm() {
        this.close(true);
    }

    ngOnInit(): void {
        this.pageDirection$ = pageDirection$;
    }

}
