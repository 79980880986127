import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import { SnackBarNotificationService } from '../notifications/services/snackbar-notification.service';

@Injectable()
export class SlowConnectionInterceptor implements HttpInterceptor {
  constructor() // private snackBarNotificationService: SnackBarNotificationService
  {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const startTime = Date.now();

    return next.handle(request).pipe(
      tap(() => {
        const endTime = Date.now();
        const duration = endTime - startTime;

        if (duration > 20000) {
          //   this.snackBarNotificationService.warning(
          //     'Internet instability detected.',
          //     'top'
          //   );
        }
      })
    );
  }
}
