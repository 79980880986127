import { devTools } from '@ngneat/elf-devtools';
import { enableProdMode, APP_INITIALIZER, importProvidersFrom, ErrorHandler } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LayoutModule } from '@root/layout/layout.module';
import { MatSelectModule } from '@angular/material/select';
import { IconSvgModule } from '@root/shared/icon-svg.module';
import { AppRoutingModule } from '@root/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { FilesCachingService } from './app/shared/services/files-caching-service/files-caching.service';
import { SpinnerInterceptor } from './app/shared/interceptors/spinner-interceptor';
import { BaseInterceptor } from '@root/shared/interceptors/base.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { Actions } from '@ngneat/effects-ng';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { SlowConnectionInterceptor } from '@root/shared/interceptors/slow-connection.interceptor';

if (environment.production) {
    enableProdMode();
}
bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, IconSvgModule, MatSelectModule, LayoutModule, MatProgressSpinnerModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (client: HttpClient) => new TranslateHttpLoader(client, 'assets/i18n/', '.json'),
                deps: [HttpClient],
            },
        })),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: initElfDevTools,
            deps: [Actions],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SlowConnectionInterceptor,
            multi: true,
        },
        FilesCachingService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
    .catch((err) => console.error(err));


export function initElfDevTools(actions: Actions) {
    return () => {
        devTools({
            name: 'Assentify',
            actionsDispatcher: actions,
        });
    };
}


Sentry.init({
    dsn: "https://d8803af30e33baddf9803d03838fefba@o4507430254673920.ingest.us.sentry.io/4507457448574976",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    environment: environment.production ? 'production' : 'qa or dev',
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});