import { Injectable, Inject } from '@angular/core';
import { OUTPUT_KEYS_STORE, OutputKeysStore } from './output-keys.store';
import {
  OutputKeysModel,
  OutputPropertyItemModel,
} from '@root/shared/models/output-keys.model';
import { AppRepository } from '@root/store/app.repository';

@Injectable({
  providedIn: 'root',
})
export class OutputKeysRepository {
  constructor(
    @Inject(OUTPUT_KEYS_STORE) private _store: OutputKeysStore,
    private appRepository: AppRepository
  ) { }

  clearStore() {
    this._store.reset();
  }

  mergeMaps(
    map1: Map<string, OutputKeysModel>,
    map2: Map<string, OutputKeysModel>
  ): Map<string, OutputKeysModel> {
    // Use the spread operator to create a new array with all key-value pairs from both maps

    const mergedEntries: [string, OutputKeysModel][] = [
      ...map1.entries(),
      ...map2.entries(),
    ];

    // Create a new Map with the merged key-value pairs
    return new Map(mergedEntries);
  }

  isMapEmpty(inputMap: Map<string, OutputKeysModel>): boolean {
    return inputMap.size === 0;
  }

  deleteOutputProperty(key: string) {
    const outputKeys = this.getOutputKeys();
    outputKeys[key] = undefined;
    this._store.update((state) => ({
      ...state,
      outputKeys: outputKeys,
    }));
  }
  deleteOutputPropertyByStepDefinition(stepId: number) {
    let outputKeys = this.getOutputKeys();
    if (!outputKeys) outputKeys = {};
    const filteredOutputKeys = this.filterMapByStepId(outputKeys, stepId);
    const filteredOutputKeysKeys = Object.keys(filteredOutputKeys);
    const filteredMap: OutputPropertyItemModel = Object.fromEntries(
      Object.entries(outputKeys).filter(
        ([key]) => !filteredOutputKeysKeys.includes(key)
      )
    );
    this._store.update((state) => ({
      ...state,
      outputKeys: filteredMap,
    }));
  }

  addOutputProperty(key: string, value: string | boolean) {
    let storedOutputKeys: OutputPropertyItemModel = this.getOutputKeys();
    if (!storedOutputKeys) {
      storedOutputKeys = {};
    }
    storedOutputKeys[key] = this.generateOutputKeysValue(key, value);

    this._store.update((state) => ({
      ...state,
      outputKeys: storedOutputKeys,
    }));
  }

  generateOutputKeysValue(
    key: string,
    value: string | boolean
  ): OutputKeysModel {
    const appConfiguration = this.appRepository.getAppConfiguration();
    if (appConfiguration) {
      return {
        blockId: appConfiguration.blockId,
        instanceId: appConfiguration.instanceId,
        tenantId: appConfiguration.tenantId,
        applicationId: appConfiguration.applicationId,
        flowName: appConfiguration.flowName,
        stepId: appConfiguration.stepId,
        stepDefinition: appConfiguration.stepDefinition,
        instanceHash: appConfiguration.instanceHash,
        flowInstanceId: appConfiguration.flowInstanceId,
        flowIdentifier: appConfiguration.flowIdentifier,
        key,
        value,
      };
    }
    return {} as OutputKeysModel;
  }

  getOutputKeys(): OutputPropertyItemModel {
    return this._store.value.outputKeys;
  }

  filterMapByStepId(
    inputMap: OutputPropertyItemModel,
    stepId: number
  ): OutputPropertyItemModel {
    const filteredMap: OutputPropertyItemModel = {};
    for (const key in inputMap) {
      // eslint-disable-next-line no-prototype-builtins
      if (inputMap.hasOwnProperty(key)) {
        if (inputMap[key]?.stepId === stepId) {
          filteredMap[key] = inputMap[key];
        }
      }
    }
    return filteredMap;
  }

  filterMapByStepDefinition(
    inputMap: OutputPropertyItemModel,
    stepDefinition: string
  ): OutputPropertyItemModel {
    const filteredMap: OutputPropertyItemModel = {};
    for (const key in inputMap) {
      // eslint-disable-next-line no-prototype-builtins
      if (inputMap.hasOwnProperty(key)) {
        if (inputMap[key].stepDefinition === stepDefinition) {
          filteredMap[key] = inputMap[key];
        }
      }
    }
    return filteredMap;
  }

  getOutputKeysByStepId(stepId: number): OutputPropertyItemModel {
    return this.filterMapByStepId(this.getOutputKeys(), stepId);
  }
}
