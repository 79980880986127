import { AppRepository } from '../../../store/app.repository';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message } from '../models/message.model';
import { CommunicationRepository } from '../store/communication.repository';
import { GroupDetails } from '../models/group-details.model';
import { MessageType } from '../models/enums/message-type';
import { environment } from 'src/environments/environment';
import { getFormData } from '@root/shared/helpers/forms-helper';
import { CreateGroup } from '../models/create-group.model';
import { SignalrService } from './signalr.service';



@Injectable({ providedIn: 'root' })
export class ChatService {

    constructor(private httpClient: HttpClient,
        private appRepository: AppRepository,
        private signalrService: SignalrService,
        private communicationRepository: CommunicationRepository) { }

    sendMessage(message: Message) {
        const httpOptions = {
            headers: new HttpHeaders(
                {
                    InterceptorSkipConfigurationHeader: ''
                })
        };
        const endPointUrl = `${environment.chatSignalrUrl}/api/chat`;
        let formData = new FormData();
        if (message.messageType === MessageType.Voice) {
            const fileContent = message.fileContent;
            delete message.fileContent;
            formData = getFormData(message);
            formData.append('fileContent', fileContent, 'audio.m4a');
        }
        else {
            formData = getFormData(message);
        }
        this.httpClient.post<any>(endPointUrl, formData, httpOptions).subscribe(() => {
        });
    }

    getGroupDetails(groupId: string, pageIndex: number): void {
        const endPointUrl = `${environment.chatSignalrUrl}/api/groups/${groupId}/${pageIndex}`;
        this.httpClient.get<{ data: GroupDetails }>(endPointUrl).subscribe(result => {
            if (result) {
                this.communicationRepository.updateSelectedGroup(result.data);
                this.signalrService.startConnection();
            }
        });
    }

    createGroup(): void {
        const group: CreateGroup = {
            interactionId: this.appRepository.getAppConfiguration().instanceId,
            tenantIdentifier: this.appRepository.getAppConfiguration().tenantId,
            clientId: null
        }
        const endPointUrl = `${environment.chatSignalrUrl}/api/groups`;
        this.httpClient.post<{ data: GroupDetails }>(endPointUrl, group).subscribe(result => {
            if (result) {
                this.communicationRepository.updateSelectedGroup(result.data);
                this.signalrService.startConnection();
            }
        });
    }
}
