import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { appConfiguration$, processedSteps$ } from '@root/store/app.store';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { StepMap } from '@root/shared/models/start.model';
import { combineLatest } from 'rxjs';
import { StepperService } from '../../services/stepper.service';
import { StepPositionEnum } from '../../models/step-position.enum';
import { ApplicationRoutes } from '@root/shared/settings/common.settings';
import { StepperCardComponent } from '../stepper-card/stepper-card.component';

@Component({
  selector: 'app-previous-steps',
  templateUrl: './previous-steps.component.html',
  styleUrls: ['./previous-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StepperCardComponent],
})
export class PreviousStepsComponent extends BaseComponent implements OnInit {

  previousStepMap: StepMap[];
  processedSteps: StepMap[];
  stepPosition = StepPositionEnum.Previous;

  constructor(
    private cdr: ChangeDetectorRef,
    private stepperService: StepperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([appConfiguration$, processedSteps$])
        .subscribe(([appConfiguration, processedSteps]) => {
          if (appConfiguration && processedSteps && appConfiguration.stepDefinition !== ApplicationRoutes.WrapUp) {
            this.previousStepMap = this.stepperService.getPreviousStepsDefinitions(processedSteps, appConfiguration).slice(-4);
            this.cdr.detectChanges();
          } else {
            this.previousStepMap = [];
            this.cdr.detectChanges();
          }
        })
    );
  }


}
