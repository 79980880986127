import { Injectable } from '@angular/core';
import { HttpContextToken, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';

export const SPINNING_CARD_ID_CONTEXT = new HttpContextToken<string>(() => null);
@Injectable({
    providedIn: 'root',
})
export class SpinnerInterceptor implements HttpInterceptor {
    numberOfRequests = 0;

    constructor(private spinnerService: SpinnerService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req?.headers?.has('InterceptorShowSpinner')) {
            const headers = req?.headers.delete('InterceptorShowSpinner');
            req = req?.clone({ headers });
            this.showLoader(req.context.get(SPINNING_CARD_ID_CONTEXT));
            return next.handle(req).pipe(finalize(() => this.onEnd()));
        }
        else {
            return next.handle(req);
        }
    }

    private onEnd(): void {
        this.numberOfRequests -= 1;
        if (this.numberOfRequests === 0) {
            this.spinnerService.stopSpinning(null);
        }
    }

    private showLoader(spinningCardId: string): void {
        this.numberOfRequests += 1;
        this.spinnerService.startSpinning(spinningCardId);
    }
}
