export const environment = {
    production: window['env' as keyof typeof window]['production'] || true,
    assentifyEndPoint: window['env' as keyof typeof window]['assentifyEndPoint'] || 'default',
    googleMapKey: window['env' as keyof typeof window]['googleMapKey'] || 'default',
    contextAwareHub: window['env' as keyof typeof window]['contextAwareHub'] || 'default',
    imageOrchestratorEndPoint:
        window['env' as keyof typeof window]['imageOrchestratorEndPoint'] || 'default',
    signMeServiceURL: window['env' as keyof typeof window]['signMeServiceURL'] || 'default',
    chatSignalrUrl: window['env' as keyof typeof window]['chatSignalrUrl'] || 'default',
    slotBookingUrl: window['env' as keyof typeof window]['slotBookingUrl'] || 'default',
    idPower: window['env' as keyof typeof window]['idPower'] || 'default',
    ipReverseLookUp: window['env' as keyof typeof window]['ipReverseLookUp'] || 'default',
    aiURL: window['env' as keyof typeof window]['aiURL'] || 'default',
    blobStorage: window['env' as keyof typeof window]['blobStorage'] || 'default',
    timeSplice: window['env' as keyof typeof window]['timeSplice'] || '250',
    videoBitsPerSecond: window['env' as keyof typeof window]['videoBitsPerSecond'] || '4500000',
    maxClipCount: window['env' as keyof typeof window]['maxClipCount'] || '2',
    brightnessLowThreshold: window['env' as keyof typeof window]['brightnessLowThreshold'] || '40',
    brightnessHighThreshold: window['env' as keyof typeof window]['brightnessHighThreshold'] || '140',
    blurrinessThreshold: window['env' as keyof typeof window]['blurrinessLowThreshold'] || '3',
    faceDetectionSizeThreshold: window['env' as keyof typeof window]['faceDetectionSizeThreshold'] || '4',
    idFaceCardDetectionSizeThreshold: window['env' as keyof typeof window]['idFaceCardDetectionSizeThreshold'] || '1500',
    videoFrameRate: window['env' as keyof typeof window]['videoFrameRate'] || 60,
    identificationDocumentCaptureScanningLimit: window['env' as keyof typeof window]['identificationDocumentCaptureScanningLimit'] || 2,
    idCardDetectionSizeThreshold: window['env' as keyof typeof window]['idCardDetectionSizeThreshold'] || '25',
    passportDetectionSizeThreshold: window['env' as keyof typeof window]['passportDetectionSizeThreshold'] || '25',
    firebaseConfigAPIKey: window['env' as keyof typeof window]['firebaseConfigAPIKey'] || '',
    firebaseConfigAuthDomain: window['env' as keyof typeof window]['firebaseConfigAuthDomain'] || '',
    firebaseConfigProjectId: window['env' as keyof typeof window]['firebaseConfigProjectId'] || '',
    firebaseConfigStorageBucket: window['env' as keyof typeof window]['firebaseConfigStorageBucket'] || '',
    firebaseConfigMessagingSenderId: window['env' as keyof typeof window]['firebaseConfigMessagingSenderId'] || '',
    firebaseConfigAppId: window['env' as keyof typeof window]['firebaseConfigAppId'] || '',
    firebaseConfigMeasurementId: window['env' as keyof typeof window]['firebaseConfigMeasurementId'] || '',
    firebaseConfigVapidKey: window['env' as keyof typeof window]['firebaseConfigVapidKey'] || '',
    faceWidthFarPercentage: window['env' as keyof typeof window]['faceWidthFarPercentage'] || 15,
    faceWidthClosePercentage: window['env' as keyof typeof window]['faceWidthClosePercentage'] || 30,
    faceWidthMobileMediumPercentage: window['env' as keyof typeof window]['faceWidthMobileMediumPercentage'] || 40,
};
