import { InjectionToken } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { GroupDetails } from '../models/group-details.model';
import { persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';

export interface CommunicationModel {
    selectedGroup: GroupDetails;
    isChatDialogOpened: boolean;
    numberOfUnreadMessages: number;
}

const store = createStore(
    {
        name: 'Communication',
    },
    withProps<CommunicationModel>({
        selectedGroup: {} as GroupDetails,
        isChatDialogOpened: false,
        numberOfUnreadMessages: 0
    })
);

export const selectedGroup$ = store.pipe(select(({ selectedGroup }) => selectedGroup));
export const isChatDialogOpened$ = store.pipe(select(({ isChatDialogOpened }) => isChatDialogOpened));
export const numberOfUnreadMessages$ = store.pipe(select(({ numberOfUnreadMessages }) => numberOfUnreadMessages));

persistState(store, {
    storage: sessionStorageStrategy,
});

export type CommunicationStore = typeof store;
export const COMMUNICATION_STORE = new InjectionToken<
    ReturnType<typeof createStore>
>('Injection Token For Communication Store', {
    providedIn: 'root',
    factory: (): CommunicationStore => store,
});
