import { Injectable } from '@angular/core';
import { AppRepository } from '../../store/app.repository';


@Injectable({
    providedIn: 'root',
})
export class SpinnerService {
    constructor(private appRepository: AppRepository) { }

    startSpinning(spinningCardId: string) {
        this.appRepository.updateIsSpinning(true, spinningCardId);
    }

    stopSpinning(spinningCardId: string) {
        this.appRepository.updateIsSpinning(false, spinningCardId);
    }

}
