<div
  class="flex flex-col sm:flex-row h-full w-full content-center items-center justify-center"
>
  <div
    class="flex flex-col h-1/2 sm:h-full w-full sm:w-1/2 content-center items-center justify-center gap-4"
  >
    <div class="p-8">
      <div>
        <span class="text-[50px]"> Are You Lost?</span>
        <hr style="border-top: 8px solid #ffc400; width: 50%" />
      </div>
      <div class="py-8">
        <span class="text-[20px]"
          >Sorry, the page you’re looking for could not be found</span
        >
      </div>
    </div>
  </div>
  <div
    class="flex h-1/2 sm:h-full w-full sm:w-1/2 content-center items-center justify-center"
  >
    <mat-icon svgIcon="404" class="icon"></mat-icon>
  </div>
</div>
