import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '@root/layout/components/layout/layout.component';
import { AreYouLostComponent } from '@root/pages/landing/components/are-you-lost/are-you-lost.component';
import { LandingComponent } from '@root/pages/landing/components/landing/landing.component';
import { ApplicationRoutes } from '@root/shared/settings/common.settings';

const routes: Routes = [
  {
    path: ApplicationRoutes.Empty,
    component: LandingComponent,
  },
  {
    path: ApplicationRoutes.AreYouLost,
    component: AreYouLostComponent,
  },
  {
    path: ApplicationRoutes.Block,
    component: LayoutComponent,
    children: [
      {
        path: ApplicationRoutes.Empty,
        loadChildren: () =>
          import('@root/pages/blocks/blocks.module').then(
            (m) => m.BlocksModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
