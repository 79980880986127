<div class="app-wrapper w-[100vw] relative">
    @if(!isScanMode){
    <app-header class="h-[10svh] flex w-full" [ngClass]="{ 'skeleton--header-box': this.showSkeleton }"></app-header>
    <app-title class="h-[6vh] flex w-full background-body-color"></app-title>
    }
    <mat-sidenav-container [ngClass]="isScanMode?'h-[100svh]':'h-[76svh] sm:h-[72svh]'" class="poppins-font background-body-color">
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
    @if(!isScanMode){
    <app-footer class="h-[8svh] sm:h-[12svh] flex w-full background-body-color" [showFooter]="showFooter" (footerEventEmitter)="handleFooterEvent()" [footerState]="footerState"></app-footer>
    } @if (showChatIcon&&!isScanMode) {
    <button (click)="onOpenChat()" class="absolute z-40 bottom-12 flex items-center justify-center sm:right-4 right-3 h-12 w-12 p-1 rounded-full
        accent-background-color chat-button">
        <img src="assets/images/svg-icons/chat.svg" alt="chat" />
        @if((numberOfUnreadMessages$|async)>0){
        <div
            class="flex items-center justify-center text-xs absolute -left-[10px] top-0 rounded-full h-5 w-5 bg-[red] text-white">
            {{numberOfUnreadMessages$|async}}
        </div>
        }
    </button> }
</div>