import { Injectable } from '@angular/core';
import { engine, loadGraphModel, setBackend, env, image, tidy, browser, dispose } from '@tensorflow/tfjs';
import { analyzeFrame } from './opencv.service';
import { BasicDetectionRepository } from '../store/basic-detection.repository';
import { log } from '@root/shared/services/utilities.service/utilities.service';
import { ProcessImageModel } from '@root/pages/blocks/identification-document-capture/models/process-card.model';
import { Rectangle } from '@root/shared/components/basic-detection/model/tensorflow-rectangle.model';
import { MediaPipeDetection } from '@root/pages/blocks/face-image-acquisition/models/face-image-acquisition';

@Injectable({
    providedIn: 'root',
})
export class TensorFlowPredictionsService {

    constructor(private basicDetectionRepository: BasicDetectionRepository,
    ) { }

    async processVideo(videoElement: HTMLVideoElement, model: any, rotateFrame90Deg = false): Promise<any> {

        let modelInput: any = videoElement;
        const [modelWidth, modelHeight] = model.inputs[0].shape.slice(1, 3);
        if (rotateFrame90Deg) {
            modelInput = this.rotateVideo90Degrees(videoElement);
        }
        const input = tidy(() => {
            const resizedInput = image.resizeBilinear(browser.fromPixels(modelInput), [modelWidth, modelHeight])
                .div(255.0).expandDims(0);

            return resizedInput;
        });

        const predictions = await model.executeAsync(input);
        const [boxes, scores, classes, valid_detections] = predictions;

        const [boxes_data, classes_data, scores_data, valid_detections_data] = await Promise.all([
            boxes.data(),
            classes.data(),
            scores.data(),
            valid_detections.data()
        ]);

        // Dispose of tensors
        input.dispose();
        dispose(predictions);
        return { boxes_data, classes_data, scores_data, valid_detections_data };
    }

    calculateIntersectionArea(rectA: Rectangle, rectB: Rectangle): number {
        const xOverlap = Math.max(0, Math.min(rectA.x + rectA.width, rectB.x + rectB.width) - Math.max(rectA.x, rectB.x));
        const yOverlap = Math.max(0, Math.min(rectA.y + rectA.height, rectB.y + rectB.height) - Math.max(rectA.y, rectB.y));
        return xOverlap * yOverlap;
    }

    findCommonRectangles(rectangles: Rectangle[]): Rectangle[] {
        const commonRectangles: Rectangle[] = [];

        for (let i = 0; i < rectangles.length - 1; i++) {
            for (let j = i + 1; j < rectangles.length; j++) {
                const totalCommonArea = this.calculateIntersectionArea(rectangles[i], rectangles[j]);
                const totalArea = rectangles[i].width * rectangles[i].height + rectangles[j].width * rectangles[j].height;
                const commonAreaPercentage = totalCommonArea / totalArea;

                if (commonAreaPercentage > 0.3) {
                    commonRectangles.push(rectangles[i]);
                    commonRectangles.push(rectangles[j]);
                }
            }
        }

        return commonRectangles;
    }

    findBiggerRectangle(rectangles: Rectangle[]): Rectangle | null {
        let commonRectangles = this.findCommonRectangles(rectangles);

        commonRectangles = commonRectangles.sort((a, b) => b.score - a.score);

        // The object with the highest score is now the first element in the array
        const highestScoreObject = commonRectangles[0];

        if (commonRectangles.length === 0) {
            return null;
        }

        let minX = Infinity;
        let minY = Infinity;
        let maxWidth = 0;
        let maxHeight = 0;

        for (const rect of commonRectangles) {
            minX = Math.min(minX, rect.x);
            minY = Math.min(minY, rect.y);
            maxWidth = Math.max(maxWidth, rect.width);
            maxHeight = Math.max(maxHeight, rect.height);
        }

        const biggerRectangle: Rectangle = {
            x: minX,
            y: minY,
            width: maxWidth,
            height: maxHeight,
            score: highestScoreObject.score,  // You can calculate a new score if needed
        };

        return biggerRectangle;
    }

    async saveModel() {
        const modelPath = 'assets/models/model.json';
        const model = await loadGraphModel(modelPath);
        await engine().startScope();
        model.save('localstorage://prediction-model');
    }

    async loadModel() {
        try {
            const loadedModel = await loadGraphModel('localstorage://prediction-model');
            return loadedModel;
        } catch (e) {
            log('Error loading model from localstorage', e);
            const modelPath = 'assets/models/model.json';
            const model = await loadGraphModel(modelPath);
            await engine().startScope();
            return model;
        }
    }

    async setBackend() {
        try {
            // setWasmPath('http://localhost:4200');
            await setBackend('wasm').then(() => {
                // Your TensorFlow code using WebAssembly backend
            });
        } catch (e) {
            if (this.isWebGLSupported()) {
                await setBackend('webgl').then(() => {
                    env().setFlags({ 'WEBGL_CPU_FORWARD': 1 });
                    // Your TensorFlow code using WebGL backend
                });
            } else {
                await setBackend('cpu').then(() => {
                    // Your TensorFlow code using CPU backend
                });
            }
        }
    }

    isWebGLSupported = () => {
        try {
            const canvas = document.createElement("canvas");
            return (
                !!window.WebGLRenderingContext &&
                (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
            );
        } catch (e) {
            return false;
        }
    };

    isPredictionHasAGoodSize(rectangles: Rectangle[], model: any, detectionSizeThreshold: number) {
        let biggerRectangle = rectangles[0];
        const [modelWidth, modelHeight] = model.inputs[0].shape.slice(1, 3);
        biggerRectangle = this.findBiggerRectangle(rectangles) ?? biggerRectangle;
        const biggerRectangleSize = biggerRectangle.width * biggerRectangle.height;
        const canvasSize = modelWidth * modelHeight;
        const percentOffcanvasSize = ((biggerRectangleSize / canvasSize) * 100);
        // log('biggerRectangleSize', biggerRectangleSize, 'canvasSize', canvasSize, 'percentOffcanvasSize', percentOffcanvasSize, 'detectionSizeThreshold', detectionSizeThreshold)
        return percentOffcanvasSize > detectionSizeThreshold;
    }

    isDetectionHasAGoodSize(detection: MediaPipeDetection, videoWidth: number, videoHeight: number, detectionSizeThreshold: number): boolean {
        // Calculate the actual size of the detected face's bounding box
        const boundingBox = detection.boundingBox;
        const faceWidth = boundingBox.width * videoWidth;
        const faceHeight = boundingBox.height * videoHeight;
        const faceSize = faceWidth * faceHeight;

        // Calculate the size of the video frame
        const videoSize = videoWidth * videoHeight;

        // Calculate the percentage of the video frame occupied by the face
        const faceSizePercentage = (faceSize / videoSize) * 100;

        // Return true if the face size percentage exceeds the detection size threshold
        return faceSizePercentage > detectionSizeThreshold;
    }

    rotateVideo90Degrees(videoElement: HTMLVideoElement): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        // Rotate and draw the video frame on the canvas
        context.save();
        context.translate(canvas.width / 2, canvas.height / 2);
        context.rotate(-Math.PI / 2);
        context.drawImage(videoElement, -videoElement.videoWidth / 2, -videoElement.videoHeight / 2);
        context.restore();
        return canvas;
    }

    getVideoResolution(mediaStream: MediaStream): { width: number, height: number } {
        const videoTracks = mediaStream.getVideoTracks();
        const settings = videoTracks[0].getSettings();
        return {
            height: settings.height,
            width: settings.width,
        }
    }

    clearBoundingBox(canvas: HTMLCanvasElement, mediaStream: MediaStream) {
        const { width, height } = this.getVideoResolution(mediaStream);
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, width, height);
    }

    drawBoundingBoxes(rectangle: Rectangle, canvas: HTMLCanvasElement, mediaStream: MediaStream, color?: string) {
        if (!color)
            color = this.basicDetectionRepository.getColorBoundingBox();

        const { width, height } = this.getVideoResolution(mediaStream);
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, width, height);
        if (rectangle?.width && rectangle?.height) {
            canvas.width = width;
            canvas.height = height;
            const newX = (width * rectangle?.x) / 256;
            const newY = (height * rectangle?.y) / 256;
            const newWidth = (width * rectangle?.width) / 256;
            const newHeight = (height * rectangle?.height) / 256;
            ctx.lineJoin = "round";
            ctx.strokeStyle = color;
            ctx.lineWidth = 5;
            this.roundedRect(ctx, newX, newY, newWidth, newHeight, 15);
            ctx.stroke();
        }
    }

    roundedRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.arcTo(x + width, y, x + width, y + height, radius);
        ctx.arcTo(x + width, y + height, x, y + height, radius);
        ctx.arcTo(x, y + height, x, y, radius);
        ctx.arcTo(x, y, x + width, y, radius);
        ctx.closePath();
    }

    async processImage(rectangle: Rectangle, mediaStream: MediaStream, videoElement: HTMLVideoElement): Promise<ProcessImageModel> {
        const videoTracks = mediaStream.getVideoTracks();
        const settings = videoTracks[0].getSettings();
        const width = settings.width;
        const height = settings.height;
        const getCardcanvas = document.createElement('canvas');
        getCardcanvas.width = width;
        getCardcanvas.height = height;
        const context = getCardcanvas.getContext('2d');
        context.drawImage(videoElement, 0, 0, width, height);
        const imageBlob = await new Promise(resolve => getCardcanvas.toBlob(resolve, 'image/jpeg')) as Blob;
        const x = (width * rectangle?.x) / 256;
        const y = (height * rectangle?.y) / 256;
        const cardWidth = (width * rectangle?.width) / 256;
        const cardHeight = (height * rectangle?.height) / 256;
        // log("Card Resolution: ", cardWidth, cardHeight);
        context.drawImage(videoElement, x, y, cardWidth, cardHeight, 0, 0, width, height);
        const imageData = context.getImageData(0, 0, width, height);
        const qualityCheck = analyzeFrame(imageData);
        // log("Card blurrinessCheck Check: ", qualityCheck?.blurrinessCheck);
        // log("Card brightnessCheck Check: ", qualityCheck?.brightnessCheck);
        const detectedBlob = await new Promise(resolve => getCardcanvas.toBlob(resolve, 'image/jpeg')) as Blob;
        return {
            qualityCheck,
            x,
            y,
            height: cardHeight,
            width: cardWidth,
            detectedBlob,
            imageBlob
        }
    }
    async processImageDetection(detection: MediaPipeDetection, mediaStream: MediaStream, videoElement: HTMLVideoElement): Promise<ProcessImageModel> {
        // Retrieve video track settings
        const videoTracks = mediaStream.getVideoTracks();
        const settings = videoTracks[0].getSettings();
        const videoWidth = settings.width;
        const videoHeight = settings.height;

        // Calculate the bounding box coordinates based on the detection
        const boundingBox = detection.boundingBox;
        const x = (boundingBox.xCenter - boundingBox.width / 2) * videoWidth;
        const y = (boundingBox.yCenter - boundingBox.height / 2) * videoHeight;
        const faceWidth = boundingBox.width * videoWidth;
        const faceHeight = boundingBox.height * videoHeight;

        // Create a smaller canvas to capture the face region
        const faceCanvas = document.createElement('canvas');
        faceCanvas.width = faceWidth;
        faceCanvas.height = faceHeight;
        const faceContext = faceCanvas.getContext('2d');

        // Draw the face region onto the smaller canvas
        faceContext.drawImage(videoElement, x, y, faceWidth, faceHeight, 0, 0, faceWidth, faceHeight);

        // Analyze the face image data for quality (blurriness, brightness, etc.)
        const faceImageData = faceContext.getImageData(0, 0, faceWidth, faceHeight);
        const qualityCheck = analyzeFrame(faceImageData);

        // Convert the cropped face region to a Blob
        const detectedBlob = await new Promise(resolve => faceCanvas.toBlob(resolve, 'image/jpeg', 0.9)) as Blob;

        // Return the processed information as a ProcessImageModel
        return {
            qualityCheck,
            x,
            y,
            height: faceHeight,
            width: faceWidth,
            detectedBlob,
            imageBlob: detectedBlob // If you need the full frame Blob, replace this with imageBlob as in your original code
        };
    }
}
