import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { StepMap } from '@root/shared/models/start.model';
import { StepPositionEnum } from '../../models/step-position.enum';
import { Router } from '@angular/router';
import { ApplicationRoutes } from '@root/shared/settings/common.settings';
import { appConfiguration$ } from '@root/store/app.store';
import { AppConfiguration } from '@root/shared/models/app-configuration.model';
import { UIService } from '@root/shared/services/ui-service/ui-service.service';
import { AppRepository } from '@root/store/app.repository';
import { LayoutService } from '@root/layout/services/layout.service';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgStyle } from '@angular/common';

@Component({
  selector: 'app-stepper-card',
  templateUrl: './stepper-card.component.html',
  styleUrls: ['./stepper-card.component.scss'],
  standalone: true,
  imports: [NgClass, MatIconModule, NgStyle]
})
export class StepperCardComponent extends BaseComponent implements OnInit {

  @Input({ required: true }) stepMap: StepMap;
  @Input({ required: true }) stepPosition: StepPositionEnum;
  appConfiguration: AppConfiguration;
  isMobile: boolean;

  constructor(private appRepository: AppRepository,
    private layoutService: LayoutService,
    private route: Router,
    private uIService: UIService,
  ) {
    super();
  }

  get isActive() {
    return this.stepPosition === StepPositionEnum.Active;
  }

  get isPrevious() {
    return this.stepPosition === StepPositionEnum.Previous;
  }

  get isNext() {
    return this.stepPosition === StepPositionEnum.Next;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      appConfiguration$.subscribe((appConfiguration: AppConfiguration) => {
        if (appConfiguration) {
          this.appConfiguration = appConfiguration;
        }
      })
    );

    this.subscriptions.add(
      this.uIService.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
      }
      ));
  }

  getIcon() {
    return this.stepMap?.stepDefinition;
  }

  getIconColor() {
    switch (this.stepPosition) {
      case StepPositionEnum.Previous:
        return {
          'color': 'var(--success-color)',
        };
      case StepPositionEnum.Active:
        return {
          'color': 'var(--accent-color)',
        };
      case StepPositionEnum.Next:
        return {
          'color': '#D3D3D3',
        };
    }
  }

  onStepSelected() {
    if (this.stepPosition === StepPositionEnum.Previous && this.appConfiguration.stepDefinition !== ApplicationRoutes.WrapUp) {
      this.appRepository.updateShowFooter({ value: true });
      this.layoutService.loadFooterDynamicComponent(null);
      this.route.navigate([`block/${this.stepMap.stepDefinition}/${this.stepMap.id}`]);
    }
  }
}
