import { Injectable, Inject } from "@angular/core";
import { NOTIFICATION_STORE, NotificationStore } from "./notification.store";

@Injectable({
    providedIn: 'root',
})
export class NotificationRepository {

    constructor(
        @Inject(NOTIFICATION_STORE) private _store: NotificationStore,
    ) { }

    updateFirebaseToken(firebaseToken: string) {
        this._store.update((state) => ({
            ...state,
            firebaseToken,
        }));
    }

    getFirebaseToken(): string {
        return this._store.getValue().firebaseToken;
    }
}