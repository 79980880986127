import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { LayoutModel } from '@root/shared/models/layout.model';
import { layoutModel$, showSkeleton$ } from '@root/store/app.store';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslateModule],
})
export class TitleComponent extends BaseComponent implements OnInit {
  layoutModel$: Observable<LayoutModel>;
  showSkeleton = true;

  ngOnInit(): void {
    this.layoutModel$ = layoutModel$;
    this.subscriptions.add(
      showSkeleton$.subscribe((data: boolean) => {
        this.showSkeleton = data;
      })
    );
  }
}
