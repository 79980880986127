import { Inject, Injectable } from '@angular/core';
import { CACHED_FILE_STORE, CachedFilesStore } from './files-caching.store';
import { CachedFile } from '../model/cached-file.model';

@Injectable({
    providedIn: 'root',
})
export class CachedFilesRepository {
    
    constructor(
        @Inject(CACHED_FILE_STORE) private cachedFilesStore: CachedFilesStore
    ) { }

    reset() {
        this.cachedFilesStore.reset();
    }

    updateCachedUrls(cachedUrls: string[]) {
        this.cachedFilesStore.update((state) => ({
            ...state,
            cachedUrls,
        }));
    }
    updateCachedFile(cachedFiles: CachedFile[]) {
        this.cachedFilesStore.update((state) => ({
            ...state,
            cachedFiles,
        }));
    }

    getCachedUrls() {
        return this.cachedFilesStore.value.cachedUrls;
    }

    getCachedFiles() {
        return this.cachedFilesStore.value.cachedFiles;
    }
}
