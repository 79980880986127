import { OperatingSystem, iosPlatforms, macosPlatforms, windowsPlatforms } from "@root/shared/settings/common.settings";
import { environment } from "src/environments/environment";

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getImage = (image: any) => {
  if (image)
    return image + `?/date=${Date.now()}`
  return undefined
}

export const capitalizeFirstLetter = (text: string) => {
  const [firstChar, ...rest] = text;
  return `${firstChar.toUpperCase()}${rest.join('')}`;
}

export const getExpiryDate = (date: string) => {
  let data = date;
  if (date) {
    let yearNum = data.toString().slice(0, 2);
    yearNum = '20' + yearNum;
    const day = data.substring(4, 6);
    const month = data.substring(2, 4);
    data = [day, month, yearNum].join('/');
  }
  return data;
}

export const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined
}

export const getBirthDate = (date: string) => {
  let data = date;
  if (date) {
    if (!data?.includes('-') && !data?.includes('/')) {
      let yearNum = data.toString().slice(0, 2);
      const currentYear =
        Number(new Date().getFullYear().toString().slice(0, 2)) + 5;
      if (Number(yearNum) < Number(currentYear)) yearNum = '20' + yearNum;
      else yearNum = '19' + yearNum;
      const day = data.substring(4, 6);
      const month = data.substring(2, 4);
      data = [day, month, yearNum].join('/');
    }
  }
  return data;
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;

  if (macosPlatforms.includes(platform)) {
    return OperatingSystem.MacOs;
  } else if (iosPlatforms.includes(platform)) {
    return OperatingSystem.IOS;
  } else if (windowsPlatforms.includes(platform)) {
    return OperatingSystem.Windows;
  } else if (/Android/.test(userAgent)) {
    return OperatingSystem.Android;
  } else if (/Linux/.test(platform)) {
    return OperatingSystem.Linux;
  }

  return null;
}

export const getMimeType = () => {
  const deviceType = getOS();
  switch (deviceType) {
    case OperatingSystem.Android:
      return 'video/webm; codecs=vp8';
    case OperatingSystem.IOS:
      return 'video/mp4';
    case OperatingSystem.Windows:
      return 'video/webm';
    case OperatingSystem.MacOs:
      return 'video/webm';
    case OperatingSystem.Linux:
      return 'video/webm';
  }
}

export const createBlobFromBase64 = (base64Data: string) => {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray]);
}

export const blobToUint8Array = (blob: Blob): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const uint8Array = new Uint8Array(arrayBuffer);
      resolve(uint8Array);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

export const blobToBase64Data = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result as string;
      resolve(base64Data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const isProduction = () => {
  return environment.production.toString() === 'true';
}

export const log = (message: string, object?: any) => {
  if (!isProduction())
    console.log(message, object);
}