import { Injectable } from '@angular/core';
import { ApplicationRoutes } from '@root/shared/settings/common.settings';
import { AppConfiguration } from '@root/shared/models/app-configuration.model';
import { StepMap } from '@root/shared/models/start.model';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  getCurrentStep(stepId: number, stepMap: StepMap[]): StepMap {
    let currentStepMap: StepMap;
    stepMap.map((stepMap: StepMap) => {
      if (stepMap.id === stepId) {
        currentStepMap = stepMap;
      }
      if (stepMap.branches?.length > 0) {
        stepMap?.branches?.map((branch) => {
          branch?.map((stepMap: StepMap) => {
            if (stepMap.id === stepId) {
              currentStepMap = stepMap;
            }
          });
        });
      }
    });
    return currentStepMap;
  }

  getSplitStepByCurrentStepParrentId(
    parentStepId: number,
    stepMap: StepMap[]
  ): StepMap {
    return stepMap.find((item) => item.id === parentStepId);
  }

  insideSplitStep(stepId: number, stepMap: StepMap[]): boolean {
    const step = stepMap.find((stepMap) => stepMap.id === stepId);
    return step ? false : true;
  }

  getSplitStepsBeforeIndex(index: number, stepMap: StepMap[]): StepMap[] {
    return stepMap
      .slice(0, index)
      .filter((item) => item.stepName === ApplicationRoutes.Split);
  }

  getPreviousSteps(
    processedSteps: StepMap[],
    appConfiguration: AppConfiguration
  ): StepMap[] {
    const index = processedSteps.findIndex(
      (step) => step.id === appConfiguration.stepId
    );
    if (index !== -1) {
      processedSteps = processedSteps.slice(0, index + 1);
    }
    let previousStepMap = processedSteps.filter(
      (stepMap) => stepMap.id !== appConfiguration.stepId
    );
    previousStepMap = Array.from(new Set(previousStepMap.map((s) => s.id))).map(
      (stepId) => {
        return previousStepMap.find((s) => s.id === stepId);
      }
    );
    return this.removeBlockLoaderStep(previousStepMap).filter(
      (stepMap) => !stepMap.isVertual
    );
  }

  getPreviousStepsDefinitions(
    processedSteps: StepMap[],
    appConfiguration: AppConfiguration
  ) {
    return this.getPreviousSteps(processedSteps, appConfiguration);
  }

  getNextStepsDefinitions(
    stepMap: StepMap[],
    processedSteps: StepMap[],
    appConfiguration: AppConfiguration
  ) {
    const previousStepMap = this.getPreviousSteps(
      processedSteps,
      appConfiguration
    );
    const previousStepMapIdsSet = new Set(
      previousStepMap.map((item) => item.id)
    );

    let nextStepMap = stepMap.filter(
      (item) => !previousStepMapIdsSet.has(item.id)
    );

    nextStepMap = Array.from(new Set(nextStepMap.map((s) => s.id))).map(
      (stepId) => {
        return nextStepMap.find((s) => s.id === stepId);
      }
    );

    const currentStep = this.getCurrentStep(
      appConfiguration.stepId,
      nextStepMap
    );

    let currentStepIndex;

    // get step index
    // Remove the previees split steps
    // Get the index of the current step
    // Remove the split steps befor it
    if (this.insideSplitStep(appConfiguration.stepId, stepMap)) {
      const parrentSplitStep = nextStepMap.find(
        (item) => item.id === currentStep.parentStepId
      );
      currentStepIndex = nextStepMap.findIndex(
        (stepMap) => stepMap.id === parrentSplitStep.id
      );
    } else {
      currentStepIndex = nextStepMap.findIndex(
        (stepMap) => stepMap.id === appConfiguration.stepId
      );
    }

    const list = this.getSplitStepsBeforeIndex(currentStepIndex, nextStepMap);
    nextStepMap = nextStepMap.filter((element) =>
      list.every((item) => item.id !== element.id)
    );

    const steps = this.removeBlockLoaderStep(nextStepMap).filter(
      (stepMap) => stepMap.id !== appConfiguration.stepId
    );

    // - Remove Split Step - get current step and check if its in the split step and remove the current split step
    if (this.insideSplitStep(appConfiguration.stepId, stepMap)) {
      return steps.filter(
        (stepMap) =>
          stepMap.id !== appConfiguration.stepId &&
          stepMap.id !== currentStep.parentStepId
      );
    }

    return steps;
  }

  removeBlockLoaderStep(stepMap: StepMap[]) {
    return stepMap.filter(
      (stepMap) => stepMap.stepDefinition !== ApplicationRoutes.BlockLoader
    );
  }

  getStepCurrentPosition(stepId: number, stepMap: StepMap[]): string {
    return (
      stepMap.findIndex((stepMap) => stepMap.id === stepId) + 1
    ).toString();
  }

  getCurrentBranch(stepId: number, parentStep: StepMap): StepMap[] {
    return parentStep.branches.find((branch) =>
      branch.find((stepMap) => stepMap.id === stepId)
    );
  }

  indexToLetter(index: number): string {
    if (index < 0 || index > 25) {
      return 'Invalid index';
    }
    return String.fromCharCode('A'.charCodeAt(0) + index);
  }

  getStepCurrentPositionInsideSpiltStep(
    stepId: number,
    stepMap: StepMap[]
  ): string {
    const currentStep = this.getCurrentStep(stepId, stepMap);
    const parentStep = stepMap.find(
      (step) => step.id === currentStep?.parentStepId
    );
    const parrentIndex =
      stepMap.findIndex((stepMap) => stepMap.id === parentStep.id) + 1;

    const currentBranch = this.getCurrentBranch(stepId, parentStep);
    const position = currentBranch.findIndex(
      (stepMap) => stepMap.id === stepId
    );
    const letter = this.indexToLetter(position);
    return `${parrentIndex}.${letter}`;
  }
}
