import { InjectionToken } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
    persistState,
    sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { DataSourceValues } from '@root/shared/models/data-source-values.model';

export interface DataSourceStoreModel {
    dataSourceValues: DataSourceValues[];
}

const store = createStore(
    {
        name: 'data-source-store',
    },
    withProps<DataSourceStoreModel>({
        dataSourceValues: [],
    })
);
persistState(store, { storage: sessionStorageStrategy });
export const dataSourceValues$ = store.pipe(select(({ dataSourceValues }) => dataSourceValues));
export type DataSourceStore = typeof store;
export const DATA_SOURCE_STORE = new InjectionToken<
    ReturnType<typeof createStore>
>('Injection Token For Data Source Store', {
    providedIn: 'root',
    factory: (): DataSourceStore => store,
});
