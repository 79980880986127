<div class="flex flex-col my-2" [ngClass]="{'items-end': !message.isAdminMessage,
'items-start':message.isAdminMessage}">
    @switch (message.messageType) { @case (typesEnum.Text) {
    <div class="flex flex-col max-w-[200px] justify-start text-white text-sm overflow-hidden
         flex-wrap rounded-lg p-2 w-max" [ngClass]="{'bg-[#AFBBC6] justify-end': message.isAdminMessage, 'bg-accent justify-start': !message.isAdminMessage}" style="overflow-wrap: anywhere">
        @for( line of message.content.split('\n'); track line){
        <div>{{ line }}</div>
        }
    </div>



    } @case (typesEnum.Image) {
    <div class="relative">
        <div class="flex absolute justify-center items-center top-1 right-1 h-8 w-8 z-50">
            <button class="flex items-center justify-center h-8 w-8 p-1 bg-neutral-800 text-neutral-400 rounded-full" (click)="displayImage(message)">
                <mat-icon class="preview-icon">fullscreen</mat-icon>
            </button>
        </div>
        <img [src]="message.filePath" class="w-[200px] h-[200px] object-contain bg-[#e5e7e9]" alt="chat" />
    </div>
    } @case (typesEnum.Video) {
    <video controls autoplay [src]="message.filePath" class="w-[200px] h-[200px]"></video> } @case (typesEnum.Voice) {
    <audio [src]="message.filePath" controls></audio> } @case (typesEnum.File) {
    <div class="flex max-w-[200px] justify-start items-center text-white text-sm rounded-lg p-2 w-max" [ngClass]="{'bg-[#AFBBC6] justify-end': message.isAdminMessage,'bg-accent justify-start':!message.isAdminMessage}">
        <span class="material-icons">
            description
        </span>
        <div class="text-nowrap overflow-hidden text-ellipsis w-[80%]">{{message.fileName}}</div>
        <button (click)="displayFile(message)">
            <mat-icon class="preview-icon">fullscreen</mat-icon>
        </button>
        <button (click)="onDownload()" class="flex items-center">
            <span class="material-icons">
                download
            </span>
        </button>

    </div>
    } }
    <div class="flex items-center">
        <h4 class="text-xxs text-neutral-700">{{message.createdAt}} </h4>
        @if(!message.isAdminMessage){
        <span class="material-icons read-icon ml-2" [ngClass]="message.isRead!=true?'text-neutral-700':'text-[blue]'">
            done_all
        </span>}
    </div>
</div>