import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-are-you-lost',
  templateUrl: './are-you-lost.component.html',
  styleUrls: ['./are-you-lost.component.scss'],
  standalone: true,
  imports: [MatIconModule, TranslateModule],
})
export class AreYouLostComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
