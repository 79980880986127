import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UIService {
  constructor(private breakpointObserver: BreakpointObserver) { }

  isDesktop$ = this.breakpointObserver
    .observe('(min-width: 1280px)')
    .pipe(map((state) => state.matches));

  ltLg$ = this.breakpointObserver
    .observe('(max-width: 1500px)')
    .pipe(map((state) => state.matches));

  gtMd$ = this.breakpointObserver
    .observe('(min-width: 960px)')
    .pipe(map((state) => state.matches));

  ltMd$ = this.breakpointObserver
    .observe('(max-width: 959px)')
    .pipe(map((state) => state.matches));

  gtSm$ = this.breakpointObserver
    .observe('(min-width: 600px)')
    .pipe(map((state) => state.matches));

  isMobile$ = this.breakpointObserver
    // .observe('(max-width: 599px)')
    .observe('(max-width: 639px)')
    .pipe(map((state) => state.matches));
  isSmMobile$ = this.breakpointObserver
    .observe('(max-width: 479px)')
    .pipe(map((state) => state.matches));


  isLtLg = () => this.breakpointObserver.isMatched('(max-width: 1279px)');

  isMobile = () => this.breakpointObserver.isMatched('(max-width: 599px)');

  isGtSm = () => this.breakpointObserver.isMatched('(min-width: 640px)');
}
