import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    animations: [
        trigger('notificationAnimation', [
            state('in', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(-100%)' }),
                animate('500ms ease-in-out')
            ]),
            transition('* => void', [
                animate('500ms ease-in-out', style({ opacity: 0, transform: 'translateY(100%)' }))
            ])
        ])
    ],
    standalone: true,
    imports: [TranslateModule, MatProgressSpinnerModule]
})
export class NotificationComponent {

}
