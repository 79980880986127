
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StepperCardComponent } from './components/stepper-card/stepper-card.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { PreviousStepsComponent } from './components/previous-steps/previous-steps.component';
import { NextStepsComponent } from './components/next-steps/next-steps.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        TranslateModule,
        StepperCardComponent,
        StepperComponent,
        PreviousStepsComponent,
        NextStepsComponent,
    ],
    exports: [
        StepperComponent
    ]
})
export class StepperModule { }
