import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './components/chat/chat.component';
import { MatButtonModule } from '@angular/material/button';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EmptyMessageComponent } from './components/empty-message/empty-message.component';
import { MessageComponent } from './components/message/message.component';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { ApplicationRoutes } from '@root/shared/settings/common.settings';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const routes: Routes = [
  {
    path: ApplicationRoutes.Empty,
    component: ChatComponent,
  },
];


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    InfiniteScrollModule,
    EmptyMessageComponent,
    MatDialogModule,
    MessageComponent,
    MatProgressSpinnerModule,
    SendMessageComponent,
    RouterModule.forChild(routes),
  ],
  declarations: [ChatComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommunicationModule { }
