import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedRepository } from '../store/shared/shared.repository';
import { Language } from '../models/language.model';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})
export class LanguagesService {
    constructor(private sharedRepository: SharedRepository,
        private httpClient: HttpClient) { }

    getLanguages(flowIdentifier: string) {
        const languages = this.sharedRepository.languages;
        if (languages.length === 0) {
            const endPointUrl = `${environment.assentifyEndPoint}Translation/Languages/${flowIdentifier}`;
            this.httpClient.get<{ data: Language[] }>(endPointUrl).subscribe(result => {
                this.sharedRepository.updateLanguages(result.data);
            });
        }
    }

}
