import { InjectionToken } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
    persistState,
    sessionStorageStrategy,
} from '@ngneat/elf-persist-state';

export interface NotificationStoreModel {
    firebaseToken: string;
}

const store = createStore(
    {
        name: 'notification-store',
    },
    withProps<NotificationStoreModel>({
        firebaseToken: null,
    })
);

persistState(store, {
    storage: sessionStorageStrategy,
});


export const firebaseToken$ = store.pipe(select(({ firebaseToken }) => firebaseToken));

export type NotificationStore = typeof store;
export const NOTIFICATION_STORE = new InjectionToken<
    ReturnType<typeof createStore>
>('Injection Token For Notification Store', {
    providedIn: 'root',
    factory: (): NotificationStore => store,
});