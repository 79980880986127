import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationModule } from './notifications/notification.module';
import { AutomaticFormValidationModule } from './automatic-form-validation/automatic-form-validation.module';

@NgModule({
  exports: [MatIconModule, AutomaticFormValidationModule, TranslateModule, FormsModule, ReactiveFormsModule],
  imports: [
    CommonModule,
    TranslateModule,
    NotificationModule,
    AutomaticFormValidationModule
  ],
})
export class SharedModule { }
