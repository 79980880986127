import { Inject, Injectable } from '@angular/core';
import { BlockLoaderModel } from '../models/block-loader.model';
import { StartModel } from '../models/start.model';
import { LANDING_STORE, LandingStore } from './landing.store';

@Injectable({
  providedIn: 'root',
})
export class LandingRepository {
  constructor(@Inject(LANDING_STORE) private _store: LandingStore) { }

  /**
   * get the block loader from store
   */
  getBlockLoader(): BlockLoaderModel {
    return this._store.getValue().blockLoader;
  }

  /**
   * update block loader in store
   */
  updateBlockLoader(blockLoader: BlockLoaderModel) {
    this._store.update((state) => ({
      ...state,
      blockLoader,
    }));
  }

  /**
   * update start model in store
   */
  updateStartInstance(startInstance: StartModel) {
    this._store.update((state) => ({
      ...state,
      startInstance,
    }));
  }

}
