import { createStore, select, withProps } from '@ngneat/elf';
import { CachedFile } from '../model/cached-file.model';
import { persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { InjectionToken } from '@angular/core';

export interface CachedFilesStoreModel {
    cachedUrls: string[];
    cachedFiles: CachedFile[];
}
const store = createStore(
    {
        name: 'cached-files',
    },
    withProps<CachedFilesStoreModel>({
        cachedUrls: [],
        cachedFiles: [],
    })
);

persistState(store, {
    storage: sessionStorageStrategy,
});

export type CachedFilesStore = typeof store;

export const cachedUrls$ = store.pipe(select(({ cachedUrls }) => cachedUrls));
export const cachedFiles$ = store.pipe(select(({ cachedFiles }) => cachedFiles));
export const CACHED_FILE_STORE = new InjectionToken<
    ReturnType<typeof createStore>
>('Injection Token For Cached Files Store', {
    providedIn: 'root',
    factory: (): CachedFilesStore => store,
});