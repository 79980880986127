<div [dir]="pageDirection$|async" class="snackbar-notification">
    <div class="snackbar-notification-wrapper">
        <mat-icon [class]="mapStatusToColor[data.status]" class="icon w-14 mx-[8px]">{{ mapStatusToIcon[data.status] ||
            'info' }}
        </mat-icon>
        <div class="message-container">
            <div [class]="mapStatusToColor[data.status]" class="font-medium text-base capitalize">
                {{ data.status|translate}}</div>
            <div class="text-sm">{{ data?.message|translate }}</div>
        </div>
        <mat-icon class="w-14" (click)="snackBarRef.dismiss()">close</mat-icon>
    </div>
</div>