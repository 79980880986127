import { InjectionToken } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { Authentication } from '../../models/authentication.model';
import { PageDirection } from '../../models/enums/page-direction.enum';
import { Language } from '../../models/language.model';
import { IdentificationDocumentCaptureExtractedMethod } from '../../models/enums/identification-document-capture-extracted-method.enum';

export interface SharedStoreModel {
  landingAttributes: Authentication | null;
  pageDirection: PageDirection;
  languages: Language[];
  identificationDocumentCaptureExtractedMethod: IdentificationDocumentCaptureExtractedMethod,
  selectedLanguage: Language;
  originalImageUrl: string;
}

const store = createStore(
  {
    name: 'shared',
  },
  withProps<SharedStoreModel>({
    landingAttributes: null,
    pageDirection: PageDirection.LTR,
    selectedLanguage: null,
    identificationDocumentCaptureExtractedMethod: null,
    languages: [],
    originalImageUrl: null
  })
);

export const originalImageUrl$ = store.pipe(select(({ originalImageUrl }) => originalImageUrl));
export const pageDirection$ = store.pipe(select(({ pageDirection }) => pageDirection));
export const languages$ = store.pipe(select(({ languages }) => languages));
export const selectedLanguage$ = store.pipe(select(({ selectedLanguage }) => selectedLanguage));
export const identificationDocumentCaptureExtractedMethod$ = store.pipe(select(({ identificationDocumentCaptureExtractedMethod }) => identificationDocumentCaptureExtractedMethod));

persistState(store, {
  storage: sessionStorageStrategy,
});

export type SharedStore = typeof store;
export const SHARED_STORE = new InjectionToken<
  ReturnType<typeof createStore>
>('Injection Token For Shared Store', {
  providedIn: 'root',
  factory: (): SharedStore => store,
});
