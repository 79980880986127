import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { AppService } from '@root/shared/services/app.service';
import { FooterState } from '@root/layout/models/footer-state.model';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { LayoutModel } from '@root/shared/models/layout.model';
import { UIService } from '@root/shared/services/ui-service/ui-service.service';
import { AppRepository } from '@root/store/app.repository';
import { appConfiguration$, isSpinning$, layoutModel$, showFooter$, showSkeleton$ } from '@root/store/app.store';
import { sleep } from '@root/shared/services/utilities.service/utilities.service';
import { LayoutService } from '@root/layout/services/layout.service';
import { Observable } from 'rxjs';
import { FooterComponent } from '../footer/footer.component';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TitleComponent } from '../title/title.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { CommunicationModule } from '@root/pages/communication/communication.module';
import { MatDialog } from '@angular/material/dialog';
import { ChatComponent } from '@root/pages/communication/components/chat/chat.component';
import { ChatService } from '@root/pages/communication/services/chat.service';
import { CommunicationRepository } from '@root/pages/communication/store/communication.repository';
import { numberOfUnreadMessages$ } from '@root/pages/communication/store/communication.store';
import { AppConfiguration } from '@root/shared/models/app-configuration.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    NgClass,
    TitleComponent,
    MatSidenavModule,
    RouterOutlet,
    FooterComponent,
    CommunicationModule,
    AsyncPipe
  ],
})
export class LayoutComponent extends BaseComponent implements OnInit {
  footerState: FooterState | undefined;
  isMobile$ = this.uIService.isMobile$;
  showSkeleton = false;
  showFooter = true;
  isSpinning$: Observable<boolean>;
  isMobile: boolean;
  numberOfUnreadMessages$: Observable<number> = numberOfUnreadMessages$;
  showChatIcon: boolean;
  isScanMode = false;

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    // Handle the back button click or any popstate event here
    this.appRepository.updateShowFooter({ value: true });
    this.layoutService.loadFooterDynamicComponent(null);
  }

  constructor(private communicationRepository: CommunicationRepository,
    private appRepository: AppRepository,
    private appService: AppService,
    private chatService: ChatService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private uIService: UIService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.documentHeight();
    window.addEventListener('resize', this.documentHeight);
    this.isSpinning$ = isSpinning$;

    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.scanned && !this.isScanMode) {
          this.isScanMode = true;
          this.cdr.detectChanges();
        }
      }
    )
    );

    this.subscriptions.add(
      this.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptions.add(
      layoutModel$.subscribe(
        async (layoutModel: LayoutModel) => {
          if (layoutModel) {
            this.footerState = {
              nextButtonTitle: layoutModel.nextButtonTitle,
              skipAbility: layoutModel.skipAbility,
              skipInformationNote: layoutModel.skipInformationNote,
              skipText: layoutModel.skipText,
            };
            await sleep(200);
            this.appRepository.updateSkeleton(false);
          }
        }
      )
    );

    this.subscriptions.add(
      showFooter$.subscribe((data: any) => {
        this.showFooter = data?.value;
      })
    );

    this.subscriptions.add(
      showSkeleton$.subscribe((data: boolean) => {
        this.showSkeleton = data;
      })
    );

    this.subscriptions.add(
      appConfiguration$.subscribe((data: AppConfiguration) => {
        this.showChatIcon = data?.chatEnabled;
        this.cdr.detectChanges();
      })
    );

  }

  documentHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  handleFooterEvent(): void {
    this.appRepository.updateSkeleton(true);
    this.appRepository.updateStepValidation(false);
    this.appRepository.updateShowFooter({ value: true });
    this.layoutService.loadFooterDynamicComponent(null);
    this.appService.getNextStep();
  }

  onOpenChat() {
    this.communicationRepository.updateIsChatDialogOpened(true);
    this.communicationRepository.resetNumberOfUnreadMessages();
    const selectedGroup = this.communicationRepository.getValue().selectedGroup;
    if (selectedGroup?.id) {
      this.chatService.getGroupDetails(
        this.communicationRepository.getValue().selectedGroup.id,
        0
      );
    } else {
      this.chatService.createGroup();
    }

    this.dialog.open(ChatComponent, {
      width: this.isMobile ? '100%' : '30%',
      height: '80dvh',
      hasBackdrop: true,
      disableClose: true,
      position: this.isMobile ? {} : { bottom: '50px', right: '50px' },
    });
  }
}
