import { InjectionToken } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';

import { BlockLoaderModel } from '../models/block-loader.model';
import { StartModel } from '../models/start.model';

export interface LandingStoreModel {
  blockLoader: BlockLoaderModel;
  startInstance: StartModel;
}

const store = createStore(
  {
    name: 'landing-store',
  },
  withProps<LandingStoreModel>({
    blockLoader: null,
    startInstance: null,
  })
);
persistState(store, {
  storage: sessionStorageStrategy,
});

export const blockLoader$ = store.pipe(select(({ blockLoader }) => blockLoader));
export const startInstance$ = store.pipe(select(({ startInstance }) => startInstance));

export type LandingStore = typeof store;
export const LANDING_STORE = new InjectionToken<
  ReturnType<typeof createStore>
>('Injection Token For Landing Store', {
  providedIn: 'root',
  factory: (): LandingStore => store,
});
