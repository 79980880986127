import { InjectionToken } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { DocumentCapture, DocumentCaptureItem } from '../models/document-capture.model';
import { DocumentStateEnum } from '../models/document-state.model';
import { DocumentCaptureKeyValues } from '../models/document-capture-key-values.model';
import { BasicStoreModel } from '@root/shared/models/basic-store.model';

export interface DocumentCaptureModel {
    documentCapture: DocumentCapture;
    selectedDocumentCapture: DocumentCaptureItem;
    capturedImage: any;
    scannedDocument: string;
    documentCaptureState: DocumentStateEnum;
    showLoader: boolean;
    infoText: string;
    documentCaptureKeyValues: BasicStoreModel<DocumentCaptureKeyValues>;
    isScanMode: boolean;
    connectionId: string;
    instanceId: string;
}

const store = createStore(
    {
        name: 'document-capture-',
    },
    withProps<DocumentCaptureModel>({
        documentCapture: null,
        selectedDocumentCapture: null,
        capturedImage: null,
        scannedDocument: null,
        documentCaptureState: null,
        showLoader: false,
        infoText: null,
        documentCaptureKeyValues: {},
        isScanMode: false,
        connectionId: null,
        instanceId: null
    })
);

export const instanceId$ = store.pipe(select(({ instanceId }) => instanceId));
export const connectionId$ = store.pipe(select(({ connectionId }) => connectionId));
export const isScanMode$ = store.pipe(select(({ isScanMode }) => isScanMode));
export const infoText$ = store.pipe(select(({ infoText }) => infoText));
export const showLoader$ = store.pipe(select(({ showLoader }) => showLoader));
export const documentCapture$ = store.pipe(select(({ documentCapture }) => documentCapture));
export const capturedImage$ = store.pipe(select(({ capturedImage }) => capturedImage));
export const scannedDocument$ = store.pipe(select(({ scannedDocument }) => scannedDocument));
export const documentCaptureState$ = store.pipe(select(({ documentCaptureState }) => documentCaptureState));

export type DocumentCaptureStore = typeof store;
export const DOCUMENT_CAPTURE_STORE = new InjectionToken<
    ReturnType<typeof createStore>
>('Injection Token For Document Capture  Store', {
    providedIn: 'root',
    factory: (): DocumentCaptureStore => store,
});