import { Injectable } from '@angular/core';
import { AppRepository } from '@root/store/app.repository';
import { Theme } from '@root/shared/models/theme.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(public appRepository: AppRepository) { }


  public setDefaultTheme() {
    document.documentElement.style.setProperty('--spinner-color', '#333333');
    document.documentElement.style.setProperty('--primary-color', '#020202');
    document.documentElement.style.setProperty('--accent-color', '#FFC400');
    document.documentElement.style.setProperty('--background-card-color', '#FAFAFA');
    document.documentElement.style.setProperty('--background-body-color', '#ffffff');
    document.documentElement.style.setProperty('--primary-text-color', '#ffffff');
  }


  public updateTheme(theme: Theme) {
    document.documentElement.style.setProperty('--primary-color', theme.accentColor);
    document.documentElement.style.setProperty('--accent-color', theme.accentColor);
    document.documentElement.style.setProperty('--background-card-color', theme.backgroundCardColor);
    document.documentElement.style.setProperty('--background-body-color', theme.backgroundBodyColor);
    document.documentElement.style.setProperty('--primary-text-color', theme.textColor);
    document.documentElement.style.setProperty('--secondary-text-color', theme.secondaryTextColor);
  }

  getTheme(): Theme {
    return this.appRepository.getTheme();
  }
}
