import { environment } from "src/environments/environment";
import { QualityCheck, QualityResponseState } from "../model/quality-check.model";

declare const cv: any;
const BRIGHTNESS_LOW_THRESHOLD = environment.brightnessLowThreshold;
const BRIGHTNESS_HIGH_THRESHOLD = environment.brightnessHighThreshold;
const BLURRINESS_THRESHOLD = environment.blurrinessThreshold;

export function calculateBrightness(imageData: ImageData): number {
    // Convert the image to grayscale
    const gray = convertToGrayscale(imageData);

    let sum = 0;
    const data = gray.data;

    // Calculate mean brightness
    for (let i = 0; i < data.length; i += 4) {
        sum += (data[i] + data[i + 1] + data[i + 2]) / 3;
    }

    const meanBrightness = sum / (data.length / 4);
    return meanBrightness;
}

export function analyzeFrame(frame: ImageData): QualityCheck {
    const brightness = calculateBrightness(frame);
    const blurriness = calculateBluriness(frame);
    return {
        brightnessCheck: getBrightnessLevel(brightness),
        blurrinessCheck: getBlurrinessLevel(blurriness),
    };
}

const getBrightnessLevel = (brightness: number) => {
    let brightnessLevel: QualityResponseState;
    if (brightness < BRIGHTNESS_LOW_THRESHOLD) {
        brightnessLevel = QualityResponseState.Low;
    } else if (brightness > BRIGHTNESS_HIGH_THRESHOLD) {
        brightnessLevel = QualityResponseState.High;
    } else {
        brightnessLevel = QualityResponseState.Medium;
    }
    return brightnessLevel;
}

const calculateBluriness = (imageData: ImageData): number => {
    const pixels = imageData.data;
    let totalDifference = 0;
    for (let i = 0; i < pixels.length - 4; i += 4) {
        // Compare the brightness difference between adjacent pixels
        const currentPixel = (pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3;
        const nextPixel = (pixels[i + 4] + pixels[i + 5] + pixels[i + 6]) / 3;
        totalDifference += Math.abs(currentPixel - nextPixel);
    }
    // Calculate the average difference
    const averageDifference = totalDifference / ((pixels.length - 4) / 4);
    return averageDifference;
};

const getBlurrinessLevel = (blurriness: number) => {
    let blurrinessLevel: QualityResponseState;
    if (blurriness < BLURRINESS_THRESHOLD) {
        blurrinessLevel = QualityResponseState.High;
    } else {
        blurrinessLevel = QualityResponseState.Low;
    }
    return blurrinessLevel;
}

export const isAllQualityCheckMediumOrHighForFace = (qualityCheck: QualityCheck) => { 
    const getBrightnessLevelValue = qualityCheck?.brightnessCheck > QualityResponseState.Low;
    return getBrightnessLevelValue;
}

export const isAllQualityCheckMediumOrHigh = (qualityCheck: QualityCheck) => {
    const getBrightnessLevelValue = getBlurrinessLevel(qualityCheck.brightnessCheck) > QualityResponseState.Low;
    const getBlurrinessLevelValue = getBlurrinessLevel(qualityCheck.blurrinessCheck) > QualityResponseState.Low;
    return getBrightnessLevelValue && getBlurrinessLevelValue
}

function convertToGrayscale(imageData: ImageData): ImageData {
    const result = new ImageData(imageData.width, imageData.height);

    for (let i = 0; i < imageData.data.length; i += 4) {
        const brightness = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
        result.data[i] = result.data[i + 1] = result.data[i + 2] = brightness;
        result.data[i + 3] = 255; // Alpha channel
    }

    return result;
}