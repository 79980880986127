import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@root/layout/components/footer/footer.component';
import { HeaderComponent } from '@root/layout/components/header/header.component';
import { LayoutComponent } from '@root/layout/components/layout/layout.component';
import { TitleComponent } from '@root/layout/components/title/title.component';
import { RaisedButtonComponent } from '@root/shared/components/raised-button/raisedButton.component';
import { SharedModule } from '@root/shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StepperModule } from './components/stepper/stepper.module';
import { NotificationComponent } from './components/notification/notification.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatTreeModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        SharedModule,
        RaisedButtonComponent,
        MatIconModule,
        StepperModule,
        TranslateModule,
        MatProgressSpinnerModule,
        HeaderComponent,
        TitleComponent,
        LayoutComponent,
        FooterComponent,
        NotificationComponent
    ],
})
export class LayoutModule { }
