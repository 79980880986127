import { Injectable, Inject } from '@angular/core';
import { PageDirection } from '../../models/enums/page-direction.enum';
import { SharedStore, SHARED_STORE } from './shared.store';
import { Language } from '../../models/language.model';
import { IdentificationDocumentCaptureExtractedMethod } from '../../models/enums/identification-document-capture-extracted-method.enum';

@Injectable({
    providedIn: 'root',
})
export class SharedRepository {
    constructor(
        @Inject(SHARED_STORE) private sharedStore: SharedStore,
    ) { }

    get languages() {
        return this.sharedStore.getValue().languages;
    }

    get originalImageUrl() {
        return this.sharedStore.getValue().originalImageUrl;
    }

    updatePageDirection(pageDirection: PageDirection): void {
        this.sharedStore.update((state) => ({
            ...state,
            pageDirection
        }));
    }

    updateIdentificationDocumentCaptureExtractedMethod(identificationDocumentCaptureExtractedMethod: IdentificationDocumentCaptureExtractedMethod) {
        this.sharedStore.update((state) => ({
            ...state,
            identificationDocumentCaptureExtractedMethod
        }));
    }

    updateLanguages(languages: Language[]): void {
        this.sharedStore.update((state) => ({
            ...state,
            languages
        }));
    }

    updateSelectedLanguage(selectedLanguage: Language) {
        this.sharedStore.update((state) => ({
            ...state,
            selectedLanguage
        }));
    }

    updateOriginalImageUrl(originalImageUrl: string) {
        this.sharedStore.update((state) => ({
            ...state,
            originalImageUrl
        }));
    }
}
