@if(showFooter){
<div [ngClass]="{
            'accent-background-color': (isMobile$ | async),
            'opacity-50':(isMobile$ | async)&&!skipValidation,
            'background-body-color': (isMobile$ | async)===false
          }" class="flex w-full h-full">

    @if(isMobile$ | async){
    <div [ngClass]="{ 'skeleton--footer-box': this.showSkeleton }" class="flex w-full h-full justify-center items-center">
        <div class="text-white flex h-full w-full text-center font-poppins-regular font-light text-[0.75rem]">
            <button class="flex h-full w-full justify-center content-center rounded-lg text-center" (click)="skipEvent()">
                <div
                    class="flex flex-col h-full w-full justify-center content-center items-center text-xl font-semibold text-[0.75] py-[0.25rem] px-[5.00rem] poppins-regular secondary-text-color">
                    <span [ngClass]="{
                  'h-6': footerState?.skipInformationNote,
                  'h-11 leading-10': !footerState?.skipInformationNote
                }" class="block">{{
                        footerState?.skipAbility
                        ? footerState?.skipText
                        : footerState?.nextButtonTitle
                        }}</span>
                    @if(footerState?.skipAbility){
                    <span [ngClass]="{
                  'h-5': footerState?.skipInformationNote,
                  'h-0': !footerState?.skipInformationNote
                }" class="block">{{ footerState?.skipInformationNote }}</span>}
                </div>
            </button>
        </div>
    </div>
    }@else{
    <div class="flex w-full h-full justify-center items-center">

        @if(footerState?.skipAbility){
        <div class="grid">
            <div class="flex items-center content-center flex-col">
                <app-raised-button [text]="footerState?.skipText" [ngStyle]="{ opacity: skipValidation ? '1' : '0.5' }" [backgroundColor]="'accent-background-color'" (triggerClickEvent)="skipEvent()"></app-raised-button>
            </div>
            @if(footerState?.skipInformationNote){
            <div class="flex items-center content-center flex-col mt-2 text-base primary-text-color">
                {{ footerState?.skipInformationNote }}
            </div>}
        </div>
        } @else {
        <div>
            <app-raised-button [text]="footerState?.nextButtonTitle" [ngStyle]="{ opacity: skipValidation ? '1' : '0.5' }" [backgroundColor]="'accent-background-color'" (triggerClickEvent)="completeEvent()"></app-raised-button>
        </div>
        }
    </div>
    }
</div>} @else {
<div class="w-full h-full flex justify-center items-center">
    <ng-container *ngTemplateOutlet="template"></ng-container>
</div>
}