import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

import { CachedFile } from './model/cached-file.model';
import { CachedFilesRepository } from './store/files-caching.repository';

@Injectable({
    providedIn: 'root',
})
export class FilesCachingService {
    constructor(
        private http: HttpClient,
        private cachedFilesRepository: CachedFilesRepository
    ) { }

    clearCashURLs() {
        this.cacheUrls = [];
        this.cachedFilesRepository.reset()
    }
    set cacheUrls(urls: string[]) {
        const cachedURL = this.cacheUrls;
        urls.map((url: string) => {
            const index = this.cacheUrls.findIndex(cacheUrl => cacheUrl === url);
            if (index === -1) {
                cachedURL.push(url)
            }
        })
        this.cachedFilesRepository.updateCachedUrls(undefined);
        this.cachedFilesRepository.updateCachedUrls(cachedURL);
        this.cacheUrls.map((url: string) => {
            this.getFile(url)
        })
    }
    get cacheUrls(): string[] {
        return this.cachedFilesRepository.getCachedUrls();
    }
    get cachedFiles(): CachedFile[] {
        return this.cachedFilesRepository.getCachedFiles();
    }
    getFile(url: string) {
        const index = this.cachedFiles.findIndex(file => file.url === url);
        if (index === -1) {
            this.http.get(url, {
                responseType: 'blob', headers: new HttpHeaders({
                    InterceptorSkipConfigurationHeader: '',
                })
            }).pipe(
                tap(blob => this.checkAndCacheFile(url, blob))
            ).subscribe(
                {
                    error(_err) { },
                    next(_value) { },
                    complete() { },
                }
            );
        }
    }
    checkAndCacheFile(url: string, cachedFile: Blob) {
        const cachedFiles = this.cachedFiles;
        const index = cachedFiles.findIndex(file => file.url === url);
        if (index === -1) {
            const objectUrl = URL.createObjectURL(cachedFile);
            cachedFiles.push({ url, objectUrl })
            this.cachedFilesRepository.updateCachedFile(undefined);
            this.cachedFilesRepository.updateCachedFile(cachedFiles);
        }
    }
}

