import { Inject, Injectable } from '@angular/core';
import { DocumentCaptureStore, DOCUMENT_CAPTURE_STORE } from './document-capture.store';
import { DocumentCapture } from '../models/document-capture.model';
import { DocumentStateEnum } from '../models/document-state.model';
import { BasicStoreModel } from '@root/shared/models/basic-store.model';
import { DocumentCaptureKeyValues } from '../models/document-capture-key-values.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentCaptureRepository {
  constructor(@Inject(DOCUMENT_CAPTURE_STORE) private _store: DocumentCaptureStore
  ) { }

  getValue() {
    return this._store.getValue();
  }

  reset() {
    this._store.reset();
  }

  getDocumentCaptureKeyValues(): BasicStoreModel<DocumentCaptureKeyValues> {
    return this._store.getValue().documentCaptureKeyValues;
  }

  changeShowToggleStatus(showLoader: boolean) {
    this._store.update((state) => ({
      ...state,
      showLoader,
    }));
  }

  updateInfoText(infoText: string) {
    this._store.update((state) => ({
      ...state,
      infoText,
    }));
  }

  updateDocumentCapture(documentCapture: DocumentCapture) {
    this._store.update((state) => ({
      ...state,
      documentCapture,
    }));
  }

  updateConnectionId(connectionId: string) {
    this._store.update((state) => ({
      ...state,
      connectionId,
    }));
  }


  updateIsScanMode(isScanMode: boolean) {
    this._store.update((state) => ({
      ...state,
      isScanMode,
    }));
  }

  updateCapturedImage(capturedImage: string) {
    this._store.update((state) => ({
      ...state,
      capturedImage,
    }));
  }

  updateScannedDocument(scannedDocument: string) {
    this._store.update((state) => ({
      ...state,
      scannedDocument
    }));
  }

  updateInstanceId(instanceId: string) {
    this._store.update((state) => ({
      ...state,
      instanceId
    }));
  }

  documentCaptureState(documentCaptureState: DocumentStateEnum) {
    this._store.update((state) => ({
      ...state,
      documentCaptureState
    }));
  }

  updateDocumentCaptureKeyValues(key: string, value: Blob, stepId: number) {
    let storedDocumentCaptureKeyValues: DocumentCaptureKeyValues = this.getDocumentCaptureKeyValues()[stepId];
    if (!storedDocumentCaptureKeyValues) {
      storedDocumentCaptureKeyValues = {};
    }
    storedDocumentCaptureKeyValues[key] = value;

    this._store.update((state) => ({
      ...state,
      documentCaptureKeyValues: {
        [stepId]: storedDocumentCaptureKeyValues,
      },
    }));

  }
}
