import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MessageType } from '../../models/enums/message-type';
import { Message } from '../../models/message.model';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { DocumentViewerComponent } from '@root/shared/components/document-viewer/document-viewer.component';
import { ImagePreviewComponent } from '@root/shared/components/image-preview/image-preview.component';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [NgClass, MatIconModule, DocumentViewerComponent, ImagePreviewComponent],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent {
  @ViewChild('audioPlayer') audioPlayer: ElementRef<HTMLAudioElement>;

  @Input({ required: true }) message: Message;
  typesEnum = MessageType;

  constructor(public cdr: ChangeDetectorRef,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) { }


  onDownload() {
    this.httpClient.get(this.message.filePath, {
      responseType: 'blob', headers: new HttpHeaders({
        process: "background"
      })
    }).subscribe((blob: Blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.message.fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    });
  }

  displayFile(message: Message) {
    this.dialog.open(DocumentViewerComponent, {
      data: message.filePath,
      width: '100%',
      height: '100dvh',
      hasBackdrop: true,
      disableClose: true
    });
  }

  displayImage(message: Message) {
    this.dialog.open(ImagePreviewComponent, {
      data: message.filePath,
      width: '100%',
      height: '100dvh',
      hasBackdrop: true,
      disableClose: true
    });
  }

}

