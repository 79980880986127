import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { appConfiguration$, stepMap$, processedSteps$ } from '@root/store/app.store';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { StepMap } from '@root/shared/models/start.model';
import { combineLatest } from 'rxjs';
import { StepperService } from '../../services/stepper.service';
import { StepPositionEnum } from '../../models/step-position.enum';
import { UIService } from '@root/shared/services/ui-service/ui-service.service';
import { pageDirection$ } from '@root/shared/store/shared/shared.store';
import { PageDirection } from '@root/shared/models/enums/page-direction.enum';
import { ApplicationRoutes } from '@root/shared/settings/common.settings';
import { StepperCardComponent } from '../stepper-card/stepper-card.component';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, StepperCardComponent],
})
export class NextStepsComponent extends BaseComponent implements OnInit {

  stepMap: StepMap[];
  stepPosition = StepPositionEnum.Next;
  isMobile: boolean;
  positionDirection: string;

  constructor(
    private uIService: UIService,
    private cdr: ChangeDetectorRef,
    private stepperService: StepperService
  ) {
    super();
  }

  ngOnInit(): void {

    this.subscriptions.add(
      this.uIService.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
      }
      ));

    this.subscriptions.add(
      combineLatest([appConfiguration$, stepMap$, processedSteps$, pageDirection$])
        .subscribe(([appConfiguration, stepMap, processedSteps, pageDirection]) => {
          this.positionDirection = pageDirection;
          if (appConfiguration && stepMap && processedSteps && appConfiguration.stepDefinition !== ApplicationRoutes.WrapUp) {
            this.stepMap = this.stepperService.getNextStepsDefinitions(stepMap, processedSteps, appConfiguration).reverse();
            this.cdr.detectChanges();
          } else {
            this.stepMap = [];
            this.cdr.detectChanges();
          }
        })
    );

  }

  getStyle(index: number) {
    const pxValue = this.isMobile ? 10 : 22;
    if (this.positionDirection === PageDirection.LTR) {
      return {
        'right': `${(index) * pxValue}px`,
      };
    } else {
      return {
        'left': `${(index) * pxValue}px`,
      };
    }
  }
}
