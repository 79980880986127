<div class="w-full h-[50px] mb-3 overflow-hidden border-[#DBE5ED] border rounded-3xl flex items-center px-3">
  <div class="w-[60%] md:w-[90%]">
    @if(!isRecording){
    <mat-form-field class="w-full">
      @if(isMobile$|async){
      <textarea class="pt-[5px]" [(ngModel)]="messageText" matInput placeholder="Enter Your Message"></textarea> }@else
      {
      <textarea class="pt-[5px]" [(ngModel)]="messageText" (keydown.enter)="onSendMessage($event)" matInput
        placeholder="Enter Your Message"></textarea> }
    </mat-form-field>
    }
  </div>
  @if(!isRecording){ @if(imageMessageLink){ @if(uploadedImage){
  <div class="relative">
    <img class="w-[40px] h-[40px] mr-2 rounded-md" [src]="imageMessageLink" />
    <button class="absolute flex justify-center items-center top-0 right-0 bg-[#DBE5ED] rounded-full w-[16px] h-[16px]"
      (click)="onDeleteSelectedImage()">
      <span class="material-icons delete-icon"> close </span>
    </button>
  </div>
  } @if(uploadedVideo){
  <div class="relative">
    <video class="w-[40px] h-[40px] mr-2 rounded-md" [src]="imageMessageLink"></video>
    <button class="absolute flex justify-center items-center top-0 right-0 bg-[#DBE5ED] rounded-full w-[16px] h-[16px]"
      (click)="onDeleteSelectedImage()">
      <span class="material-icons delete-icon"> close </span>
    </button>
  </div>
  } } @if(uploadedFile){
  <div class="relative flex items-center px-2 py-1 mr-2 border rounded-lg border-neutral-700">
    <span class="material-icons"> description </span>
    <h2 class="text-xxs">{{ uploadedFile.name }}</h2>
    <button class="absolute flex justify-center items-center top-0 right-0 bg-[#DBE5ED] rounded-full w-[16px] h-[16px]"
      (click)="onDeleteSelectedFile()">
      <span class="material-icons delete-icon"> close </span>
    </button>
  </div>
  } @if(showEmoji){
  <emoji-mart class="z-[9999]" (emojiClick)="onSelectEmoji($event)"
    [style]="{ position: 'absolute', bottom: '20px', right: '20px' }" [darkMode]="false"></emoji-mart>}
  <button (click)="onshowEmoji()" class="flex" (clickOutside)="onClickedOutside($event)">
    <span class="material-icons text-[#DBE5ED]"> sentiment_satisfied_alt </span>
  </button> @if(!uploadedImage&&!uploadedFile){
  <button [matMenuTriggerFor]="menu" class="flex items-center justify-center">
    <span class="material-icons cursor-pointer text-[#DBE5ED]">
      attach_file
    </span>
  </button>
  <mat-menu #menu="matMenu">
    <button (click)="imageInputEle.click()" mat-menu-item>
      <mat-icon class="attach-icons">insert_photo</mat-icon>
      <span class="pl-2 text-sm text-neutral-700">Photo</span>
    </button>
    <button (click)="videoInputEle.click()" mat-menu-item>
      <mat-icon class="attach-icons">videocam</mat-icon>
      <span class="pl-2 text-sm text-neutral-700">Video</span>
    </button>
    <button (click)="fileInputEle.click()" mat-menu-item>
      <mat-icon class="attach-icons">insert_drive_file</mat-icon>
      <span class="pl-2 text-sm text-neutral-700">Documents</span>
    </button>
  </mat-menu>
  <div class="hidden-file-uploader">
    <input (change)="onImageUploaded($event)" [accept]="imageExtensions" #imageInputEle type="file" name="file"
      [ngStyle]="{ display: 'none' }" />
  </div>
  <div class="hidden-file-uploader">
    <input (change)="onVideoUploaded($event)" [accept]="videoExtensions" #videoInputEle type="file" name="file"
      [ngStyle]="{ display: 'none' }" />
  </div>
  <div class="hidden-file-uploader">
    <input (change)="onFileUploaded($event)" [accept]="fileExtensions" #fileInputEle type="file" name="file"
      [ngStyle]="{ display: 'none' }" />
  </div>
  @if(isMicrophoneAllowed){
  <button (click)="startRecording()" class="mr-3 flex items-center justify-center">
    <span class="material-icons text-[#DBE5ED]"> mic </span>
  </button> } }}@else {
  <button (click)="onDeleteRecord()">
    <span class="material-icons text-[red]"> delete_outline </span>
  </button>
  <audio class="h-[30px]" #audioPlayer controls></audio>
  <div class="ml-2">{{ recordingTime }}</div>

  <button (click)="stopRecording()" class="mr-3 flex items-center justify-center">
    <span class="material-icons text-accent">
      check_box
    </span>
  </button> } @if(isSpinning$|async){
  <mat-spinner color="accent"></mat-spinner>
  }@else if(!isRecording||(isRecording&&isStopRecording)) {
  <button type="button" (click)="onSendMessage($event)" mat-mini-fab class="bg-accent flex items-center justify-center">
    <span class="material-icons text-white send-icon"> send </span>
  </button> }
</div>