import { MatDateFormats } from '@angular/material/core';
import { CountryOperationItem } from '@root/shared/models/country-operation-item.model';

export const DateFormats: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
export const enum regexDescriptor {
  DateRegex = '^(0[1-9]|[12][0-9]|3[01])\\/(0[1-9]|1[0-2])\\/\\d{4}$',
  EmailRegex = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
}

export const DDMMYYYY = 'DD/MM/YYYY';

export const MMDDYYYY = 'MM/DD/YYYY';

export const enum ApplicationRoutes {
  Empty = '',
  TermsConditions = 'TermsConditions',
  InformativeOnBoarding = 'InformativeOnBoarding',
  FaceImageAcquisition = 'FaceImageAcquisition',
  LiveCapture = 'liveCapture',
  PinCode = 'PinCode',
  ServiceSelection = 'ServiceSelection',
  IdentificationDocumentCapture = 'IdentificationDocumentCapture',
  DocumentCapture = 'DocumentCapture',
  AssistedDataEntry = 'AssistedDataEntry',
  Questionnaire = 'Questionnaire',
  LocationSelection = 'LocationSelection',
  VideoImageCapture = 'VideoImageCapture',
  PaymentProcessing = 'PaymentProcessing',
  ProposalReview = 'ProposalReview',
  FormOfPayment = 'FormOfPayment',
  ContextAwareSigning = 'ContextAwareSigning',
  SetCredentials = 'SetCredentials',
  SlotBooking = 'SlotBooking',
  WrapUp = 'WrapUp',
  Login = 'Login',
  Split = 'Split',
  FlowStep = 'FlowStep',
  IdentificationDocumentCaptureTemplates = 'IdentificationDocumentCaptureTemplates',
  CaptureDocumentByCamera = 'CaptureDocumentByCamera',
  CaptureDocument = 'CaptureDocument',
  IdentificationDocumentCaptureScanUpload = 'IdentificationDocumentCaptureScanUpload',
  IdentificationDocumentCaptureMobileScan = 'IdentificationDocumentCaptureMobileScan',
  IdentificationDocumentCaptureResult = 'IdentificationDocumentCaptureResult',
  IdentificationDocumentCaptureLiveDetection = 'IdentificationDocumentCaptureLiveDetection',
  IdentificationDocumentInfo = 'IdentificationDocumentInfo',
  Block = 'block',
  BlockLoader = 'BlockLoader',
  AreYouLost = 'AreYouLost',
  CaptureAndVerify = 'CaptureAndVerify',
  DocumentCaptureScan = 'DocumentCaptureScan',
  DocumentCaptureScanFromMobile = 'DocumentCaptureScanFromMobile',
  DocumentCaptureLive = 'DocumentCaptureLive',
  ManualOperations = 'ManualProcessing',
  StartProcessing = 'StartProcessing'
}

export const DocumentsTypeLabel = new Map<number, string>([
  [1, 'identification-document-capture.passport'],
  [2, 'identification-document-capture.id-card'],
  [3, 'identification-document-capture.other'],
]);

export const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
export const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
export const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

export const enum OperatingSystem {
  MacOs = 'Mac Os',
  IOS = 'IOS',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux',
}

export const enum appConfiguration {
  stepId = 'stepId'
}

export const enum IdentificationDocumentCaptureOutputKeys {
  IsFailedFront = 'OnBoardMe_IdentificationDocumentCapture_IsFailedFront',
  SkippedStatus = 'OnBoardMe_IdentificationDocumentCapture_SkippedStatus',
  IsFailedBack = 'OnBoardMe_IdentificationDocumentCapture_IsFailedBack',
  IsBackAuth = 'OnBoardMe_IdentificationDocumentCapture_IsBackAuth',
  IsFrontAuth = 'OnBoardMe_IdentificationDocumentCapture_IsFrontAuth',
  IsManualCapture = 'OnBoardMe_IdentificationDocumentCapture_IsManualCapture'
}

export const enum BlockLoaderOutputKeys {
  UserAgent = 'OnBoardMe_BlockLoader_UserAgent',
  DeviceName = 'OnBoardMe_BlockLoader_DeviceName',
  TimeStarted = 'OnBoardMe_BlockLoader_TimeStarted',
}
export const enum SkippedAfterNFailsKeys {
  SkippedStatus = '_SkippedStatus',
  IsSkippedAfterNFails = '_IsSkippedAfterNFails',
}

export const enum WrapUpOutputKeys {
  TimeEnded = 'OnBoardMe_WrapUp_TimeEnded',
}

export const enum BasicOutputKeyLabel {
  key = 'OnBoardMe_Property_'
}

export const enum StepDefinition {
  ContextAwareSigning = 'ContextAwareSigning',
}

export const CountryList: CountryOperationItem[] = [
  {
    "FullName": "Afghanistan",
    "ISOCode3": "AFG",
    "ISOCode2": "AF",
    "CountryShort": "AFGHANISTAN",
    "CountryCode": "+93 (Afghanistan)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Albania",
    "ISOCode3": "ALB",
    "ISOCode2": "AL",
    "CountryShort": "ALBANIA",
    "CountryCode": "+355 (Albania)",
    "Regex": /^\d{9,10}$/
  },
  {
    "FullName": "Algeria",
    "ISOCode3": "DZA",
    "ISOCode2": "DZ",
    "CountryShort": "ALGERIA",
    "CountryCode": "+213 (Algeria)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Andorra",
    "ISOCode3": "AND",
    "ISOCode2": "AD",
    "CountryShort": "ANDORRA",
    "CountryCode": "+376 (Andorra)",
    "Regex": /^\d{6}$/
  },
  {
    "FullName": "Angola",
    "ISOCode3": "AGO",
    "ISOCode2": "AO",
    "CountryShort": "ANGOLA",
    "CountryCode": "+244 (Angola)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Antigua and Barbuda",
    "ISOCode3": "ATG",
    "ISOCode2": "AG",
    "CountryShort": "ANTIGUA AND BARBUDA",
    "CountryCode": "+1-268 (Antigua and Barbuda)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Argentina",
    "ISOCode3": "ARG",
    "ISOCode2": "AR",
    "CountryShort": "ARGENTINA",
    "CountryCode": "+54 (Argentina)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Armenia",
    "ISOCode3": "ARM",
    "ISOCode2": "AM",
    "CountryShort": "ARMENIA",
    "CountryCode": "+374 (Armenia)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Australia",
    "ISOCode3": "AUS",
    "ISOCode2": "AU",
    "CountryShort": "AUSTRALIA",
    "CountryCode": "+61 (Australia)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Austria",
    "ISOCode3": "AUT",
    "ISOCode2": "AT",
    "CountryShort": "AUSTRIA",
    "CountryCode": "+43 (Austria)",
    "Regex": /^\d{10,11}$/
  },
  {
    "FullName": "Azerbaijan",
    "ISOCode3": "AZE",
    "ISOCode2": "AZ",
    "CountryShort": "AZERBAIJAN",
    "CountryCode": "+994 (Azerbaijan)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Bahamas",
    "ISOCode3": "BHS",
    "ISOCode2": "BS",
    "CountryShort": "BAHAMAS",
    "CountryCode": "+1-242 (Bahamas)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Bahrain",
    "ISOCode3": "BHR",
    "ISOCode2": "BH",
    "CountryShort": "BAHRAIN",
    "CountryCode": "+973 (Bahrain)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Bangladesh",
    "ISOCode3": "BGD",
    "ISOCode2": "BD",
    "CountryShort": "BANGLADESH",
    "CountryCode": "+880 (Bangladesh)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Barbados",
    "ISOCode3": "BRB",
    "ISOCode2": "BB",
    "CountryShort": "BARBADOS",
    "CountryCode": "+1-246 (Barbados)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Belarus",
    "ISOCode3": "BLR",
    "ISOCode2": "BY",
    "CountryShort": "BELARUS",
    "CountryCode": "+375 (Belarus)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Belgium",
    "ISOCode3": "BEL",
    "ISOCode2": "BE",
    "CountryShort": "BELGIUM",
    "CountryCode": "+32 (Belgium)",
    "Regex": /^\d{9,10}$/
  },
  {
    "FullName": "Belize",
    "ISOCode3": "BLZ",
    "ISOCode2": "BZ",
    "CountryShort": "BELIZE",
    "CountryCode": "+501 (Belize)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Benin",
    "ISOCode3": "BEN",
    "ISOCode2": "BJ",
    "CountryShort": "BENIN",
    "CountryCode": "+229 (Benin)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Bhutan",
    "ISOCode3": "BTN",
    "ISOCode2": "BT",
    "CountryShort": "BHUTAN",
    "CountryCode": "+975 (Bhutan)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Bolivia",
    "ISOCode3": "BOL",
    "ISOCode2": "BO",
    "CountryShort": "BOLIVIA",
    "CountryCode": "+591 (Bolivia)",
    "Regex": /^\d{7,8}$/
  },
  {
    "FullName": "Bosnia and Herzegovina",
    "ISOCode3": "BIH",
    "ISOCode2": "BA",
    "CountryShort": "BOSNIA AND HERZEGOVINA",
    "CountryCode": "+387 (Bosnia and Herzegovina)",
    "Regex": /^\d{8,9}$/
  },
  {
    "FullName": "Botswana",
    "ISOCode3": "BWA",
    "ISOCode2": "BW",
    "CountryShort": "BOTSWANA",
    "CountryCode": "+267 (Botswana)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Brazil",
    "ISOCode3": "BRA",
    "ISOCode2": "BR",
    "CountryShort": "BRAZIL",
    "CountryCode": "+55 (Brazil)",
    "Regex": /^\d{11}$/
  },
  {
    "FullName": "Brunei Darussalam",
    "ISOCode3": "BRN",
    "ISOCode2": "BN",
    "CountryShort": "BRUNEI DARUSSALAM",
    "CountryCode": "+673 (Brunei Darussalam)",
    "Regex": /^\d{7,8}$/
  },
  {
    "FullName": "Bulgaria",
    "ISOCode3": "BGR",
    "ISOCode2": "BG",
    "CountryShort": "BULGARIA",
    "CountryCode": "+359 (Bulgaria)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Burkina Faso",
    "ISOCode3": "BFA",
    "ISOCode2": "BF",
    "CountryShort": "BURKINA FASO",
    "CountryCode": "+226 (Burkina Faso)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Burundi",
    "ISOCode3": "BDI",
    "ISOCode2": "BI",
    "CountryShort": "BURUNDI",
    "CountryCode": "+257 (Burundi)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Cambodia",
    "ISOCode3": "KHM",
    "ISOCode2": "KH",
    "CountryShort": "CAMBODIA",
    "CountryCode": "+855 (Cambodia)",
    "Regex": /^\d{8,9}$/
  },
  {
    "FullName": "Cameroon",
    "ISOCode3": "CMR",
    "ISOCode2": "CM",
    "CountryShort": "CAMEROON",
    "CountryCode": "+237 (Cameroon)",
    "Regex": /^\d{8,9}$/
  },
  {
    "FullName": "Canada",
    "ISOCode3": "CAN",
    "ISOCode2": "CA",
    "CountryShort": "CANADA",
    "CountryCode": "+1 (Canada)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Cape Verde",
    "ISOCode3": "CPV",
    "ISOCode2": "CV",
    "CountryShort": "CAPE VERDE",
    "CountryCode": "+238 (Cape Verde)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Central African Republic",
    "ISOCode3": "CAF",
    "ISOCode2": "CF",
    "CountryShort": "CENTRAL AFRICAN REPUBLIC",
    "CountryCode": "+236 (Central African Republic)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Chad",
    "ISOCode3": "TCD",
    "ISOCode2": "TD",
    "CountryShort": "CHAD",
    "CountryCode": "+235 (Chad)",
    "Regex": /^\d{6,7}$/
  },
  {
    "FullName": "Chile",
    "ISOCode3": "CHL",
    "ISOCode2": "CL",
    "CountryShort": "CHILE",
    "CountryCode": "+56 (Chile)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "China",
    "ISOCode3": "CHN",
    "ISOCode2": "CN",
    "CountryShort": "CHINA",
    "CountryCode": "+86 (China)",
    "Regex": /^\d{11}$/
  },
  {
    "FullName": "Colombia",
    "ISOCode3": "COL",
    "ISOCode2": "CO",
    "CountryShort": "COLOMBIA",
    "CountryCode": "+57 (Colombia)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Comoros",
    "ISOCode3": "COM",
    "ISOCode2": "KM",
    "CountryShort": "COMOROS",
    "CountryCode": "+269 (Comoros)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Congo (Brazzaville)",
    "ISOCode3": "COG",
    "ISOCode2": "CG",
    "CountryShort": "CONGO (BRAZZAVILLE)",
    "CountryCode": "+242 (Congo (Brazzaville))",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Congo (Kinshasa)",
    "ISOCode3": "COD",
    "ISOCode2": "CD",
    "CountryShort": "CONGO (KINSHASA)",
    "CountryCode": "+243 (Congo (Kinshasa))",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Costa Rica",
    "ISOCode3": "CRI",
    "ISOCode2": "CR",
    "CountryShort": "COSTA RICA",
    "CountryCode": "+506 (Costa Rica)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Croatia",
    "ISOCode3": "HRV",
    "ISOCode2": "HR",
    "CountryShort": "CROATIA",
    "CountryCode": "+385 (Croatia)",
    "Regex": /^\d{8,9}$/
  },
  {
    "FullName": "Cuba",
    "ISOCode3": "CUB",
    "ISOCode2": "CU",
    "CountryShort": "CUBA",
    "CountryCode": "+53 (Cuba)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Cyprus",
    "ISOCode3": "CYP",
    "ISOCode2": "CY",
    "CountryShort": "CYPRUS",
    "CountryCode": "+357 (Cyprus)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Czech Republic",
    "ISOCode3": "CZE",
    "ISOCode2": "CZ",
    "CountryShort": "CZECH REPUBLIC",
    "CountryCode": "+420 (Czech Republic)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Denmark",
    "ISOCode3": "DNK",
    "ISOCode2": "DK",
    "CountryShort": "DENMARK",
    "CountryCode": "+45 (Denmark)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Djibouti",
    "ISOCode3": "DJI",
    "ISOCode2": "DJ",
    "CountryShort": "DJIBOUTI",
    "CountryCode": "+253 (Djibouti)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Dominica",
    "ISOCode3": "DMA",
    "ISOCode2": "DM",
    "CountryShort": "DOMINICA",
    "CountryCode": "+1-767 (Dominica)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Dominican Republic",
    "ISOCode3": "DOM",
    "ISOCode2": "DO",
    "CountryShort": "DOMINICAN REPUBLIC",
    "CountryCode": "+1-809 (Dominican Republic)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Ecuador",
    "ISOCode3": "ECU",
    "ISOCode2": "EC",
    "CountryShort": "ECUADOR",
    "CountryCode": "+593 (Ecuador)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Egypt",
    "ISOCode3": "EGY",
    "ISOCode2": "EG",
    "CountryShort": "EGYPT",
    "CountryCode": "+20 (Egypt)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "El Salvador",
    "ISOCode3": "SLV",
    "ISOCode2": "SV",
    "CountryShort": "EL SALVADOR",
    "CountryCode": "+503 (El Salvador)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Equatorial Guinea",
    "ISOCode3": "GNQ",
    "ISOCode2": "GQ",
    "CountryShort": "EQUATORIAL GUINEA",
    "CountryCode": "+240 (Equatorial Guinea)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Eritrea",
    "ISOCode3": "ERI",
    "ISOCode2": "ER",
    "CountryShort": "ERITREA",
    "CountryCode": "+291 (Eritrea)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Estonia",
    "ISOCode3": "EST",
    "ISOCode2": "EE",
    "CountryShort": "ESTONIA",
    "CountryCode": "+372 (Estonia)",
    "Regex": /^\d{7,8}$/
  },
  {
    "FullName": "Ethiopia",
    "ISOCode3": "ETH",
    "ISOCode2": "ET",
    "CountryShort": "ETHIOPIA",
    "CountryCode": "+251 (Ethiopia)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Fiji",
    "ISOCode3": "FJI",
    "ISOCode2": "FJ",
    "CountryShort": "FIJI",
    "CountryCode": "+679 (Fiji)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Finland",
    "ISOCode3": "FIN",
    "ISOCode2": "FI",
    "CountryShort": "FINLAND",
    "CountryCode": "+358 (Finland)",
    "Regex": /^\d{9,10}$/
  },
  {
    "FullName": "France",
    "ISOCode3": "FRA",
    "ISOCode2": "FR",
    "CountryShort": "FRANCE",
    "CountryCode": "+33 (France)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Gabon",
    "ISOCode3": "GAB",
    "ISOCode2": "GA",
    "CountryShort": "GABON",
    "CountryCode": "+241 (Gabon)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Gambia",
    "ISOCode3": "GMB",
    "ISOCode2": "GM",
    "CountryShort": "GAMBIA",
    "CountryCode": "+220 (Gambia)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Georgia",
    "ISOCode3": "GEO",
    "ISOCode2": "GE",
    "CountryShort": "GEORGIA",
    "CountryCode": "+995 (Georgia)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Germany",
    "ISOCode3": "DEU",
    "ISOCode2": "DE",
    "CountryShort": "GERMANY",
    "CountryCode": "+49 (Germany)",
    "Regex": /^\d{9,10}$/
  },
  {
    "FullName": "Ghana",
    "ISOCode3": "GHA",
    "ISOCode2": "GH",
    "CountryShort": "GHANA",
    "CountryCode": "+233 (Ghana)",
    "Regex": /^\d{9,10}$/
  },
  {
    "FullName": "Greece",
    "ISOCode3": "GRC",
    "ISOCode2": "GR",
    "CountryShort": "GREECE",
    "CountryCode": "+30 (Greece)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Grenada",
    "ISOCode3": "GRD",
    "ISOCode2": "GD",
    "CountryShort": "GRENADA",
    "CountryCode": "+1-473 (Grenada)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Guatemala",
    "ISOCode3": "GTM",
    "ISOCode2": "GT",
    "CountryShort": "GUATEMALA",
    "CountryCode": "+502 (Guatemala)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Guinea",
    "ISOCode3": "GIN",
    "ISOCode2": "GN",
    "CountryShort": "GUINEA",
    "CountryCode": "+224 (Guinea)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Guinea-Bissau",
    "ISOCode3": "GNB",
    "ISOCode2": "GW",
    "CountryShort": "GUINEA-BISSAU",
    "CountryCode": "+245 (Guinea-Bissau)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Guyana",
    "ISOCode3": "GUY",
    "ISOCode2": "GY",
    "CountryShort": "GUYANA",
    "CountryCode": "+592 (Guyana)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Haiti",
    "ISOCode3": "HTI",
    "ISOCode2": "HT",
    "CountryShort": "HAITI",
    "CountryCode": "+509 (Haiti)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Honduras",
    "ISOCode3": "HND",
    "ISOCode2": "HN",
    "CountryShort": "HONDURAS",
    "CountryCode": "+504 (Honduras)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Hungary",
    "ISOCode3": "HUN",
    "ISOCode2": "HU",
    "CountryShort": "HUNGARY",
    "CountryCode": "+36 (Hungary)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Iceland",
    "ISOCode3": "ISL",
    "ISOCode2": "IS",
    "CountryShort": "ICELAND",
    "CountryCode": "+354 (Iceland)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "India",
    "ISOCode3": "IND",
    "ISOCode2": "IN",
    "CountryShort": "INDIA",
    "CountryCode": "+91 (India)",
    "Regex": /^\d{10,12}$/
  },
  {
    "FullName": "Indonesia",
    "ISOCode3": "IDN",
    "ISOCode2": "ID",
    "CountryShort": "INDONESIA",
    "CountryCode": "+62 (Indonesia)",
    "Regex": /^\d{9,12}$/
  },
  {
    "FullName": "Iran",
    "ISOCode3": "IRN",
    "ISOCode2": "IR",
    "CountryShort": "IRAN",
    "CountryCode": "+98 (Iran)",
    "Regex": /^\d{10,11}$/
  },
  {
    "FullName": "Iraq",
    "ISOCode3": "IRQ",
    "ISOCode2": "IQ",
    "CountryShort": "IRAQ",
    "CountryCode": "+964 (Iraq)",
    "Regex": /^\d{8,10}$/
  },
  {
    "FullName": "Ireland",
    "ISOCode3": "IRL",
    "ISOCode2": "IE",
    "CountryShort": "IRELAND",
    "CountryCode": "+353 (Ireland)",
    "Regex": /^\d{9,10}$/
  },
  {
    "FullName": "Italy",
    "ISOCode3": "ITA",
    "ISOCode2": "IT",
    "CountryShort": "ITALY",
    "CountryCode": "+39 (Italy)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Jamaica",
    "ISOCode3": "JAM",
    "ISOCode2": "JM",
    "CountryShort": "JAMAICA",
    "CountryCode": "+1-876 (Jamaica)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Japan",
    "ISOCode3": "JPN",
    "ISOCode2": "JP",
    "CountryShort": "JAPAN",
    "CountryCode": "+81 (Japan)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Jordan",
    "ISOCode3": "JOR",
    "ISOCode2": "JO",
    "CountryShort": "JORDAN",
    "CountryCode": "+962 (Jordan)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Kazakhstan",
    "ISOCode3": "KAZ",
    "ISOCode2": "KZ",
    "CountryShort": "KAZAKHSTAN",
    "CountryCode": "+7 (Kazakhstan)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Kenya",
    "ISOCode3": "KEN",
    "ISOCode2": "KE",
    "CountryShort": "KENYA",
    "CountryCode": "+254 (Kenya)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Kiribati",
    "ISOCode3": "KIR",
    "ISOCode2": "KI",
    "CountryShort": "KIRIBATI",
    "CountryCode": "+686 (Kiribati)",
    "Regex": /^\d{5}$/
  },
  {
    "FullName": "Korea (North)",
    "ISOCode3": "PRK",
    "ISOCode2": "KP",
    "CountryShort": "KOREA (NORTH)",
    "CountryCode": "+850 (Korea (North))",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Korea (South)",
    "ISOCode3": "KOR",
    "ISOCode2": "KR",
    "CountryShort": "KOREA (SOUTH)",
    "CountryCode": "+82 (Korea (South))",
    "Regex": /^\d{9,11}$/
  },
  {
    "FullName": "Kuwait",
    "ISOCode3": "KWT",
    "ISOCode2": "KW",
    "CountryShort": "KUWAIT",
    "CountryCode": "+965 (Kuwait)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Kyrgyzstan",
    "ISOCode3": "KGZ",
    "ISOCode2": "KG",
    "CountryShort": "KYRGYZSTAN",
    "CountryCode": "+996 (Kyrgyzstan)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Laos",
    "ISOCode3": "LAO",
    "ISOCode2": "LA",
    "CountryShort": "LAOS",
    "CountryCode": "+856 (Laos)",
    "Regex": /^\d{8,9}$/
  },
  {
    "FullName": "Latvia",
    "ISOCode3": "LVA",
    "ISOCode2": "LV",
    "CountryShort": "LATVIA",
    "CountryCode": "+371 (Latvia)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Lebanon",
    "ISOCode3": "LBN",
    "ISOCode2": "LB",
    "CountryShort": "LEBANON",
    "CountryCode": "+961 (Lebanon)",
    "Regex": /^\d{7,8}$/
  },
  {
    "FullName": "Lesotho",
    "ISOCode3": "LSO",
    "ISOCode2": "LS",
    "CountryShort": "LESOTHO",
    "CountryCode": "+266 (Lesotho)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Liberia",
    "ISOCode3": "LBR",
    "ISOCode2": "LR",
    "CountryShort": "LIBERIA",
    "CountryCode": "+231 (Liberia)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Libya",
    "ISOCode3": "LBY",
    "ISOCode2": "LY",
    "CountryShort": "LIBYA",
    "CountryCode": "+218 (Libya)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Liechtenstein",
    "ISOCode3": "LIE",
    "ISOCode2": "LI",
    "CountryShort": "LIECHTENSTEIN",
    "CountryCode": "+423 (Liechtenstein)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Lithuania",
    "ISOCode3": "LTU",
    "ISOCode2": "LT",
    "CountryShort": "LITHUANIA",
    "CountryCode": "+370 (Lithuania)",
    "Regex": /^\d{8,12}$/
  },
  {
    "FullName": "Luxembourg",
    "ISOCode3": "LUX",
    "ISOCode2": "LU",
    "CountryShort": "LUXEMBOURG",
    "CountryCode": "+352 (Luxembourg)",
    "Regex": /^\d{6,10}$/
  },
  {
    "FullName": "Macedonia",
    "ISOCode3": "MKD",
    "ISOCode2": "MK",
    "CountryShort": "MACEDONIA",
    "CountryCode": "+389 (Macedonia)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Madagascar",
    "ISOCode3": "MDG",
    "ISOCode2": "MG",
    "CountryShort": "MADAGASCAR",
    "CountryCode": "+261 (Madagascar)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Malawi",
    "ISOCode3": "MWI",
    "ISOCode2": "MW",
    "CountryShort": "MALAWI",
    "CountryCode": "+265 (Malawi)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Malaysia",
    "ISOCode3": "MYS",
    "ISOCode2": "MY",
    "CountryShort": "MALAYSIA",
    "CountryCode": "+60 (Malaysia)",
    "Regex": /^\d{9,10}$/
  },
  {
    "FullName": "Maldives",
    "ISOCode3": "MDV",
    "ISOCode2": "MV",
    "CountryShort": "MALDIVES",
    "CountryCode": "+960 (Maldives)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Mali",
    "ISOCode3": "MLI",
    "ISOCode2": "ML",
    "CountryShort": "MALI",
    "CountryCode": "+223 (Mali)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Malta",
    "ISOCode3": "MLT",
    "ISOCode2": "MT",
    "CountryShort": "MALTA",
    "CountryCode": "+356 (Malta)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Marshall Islands",
    "ISOCode3": "MHL",
    "ISOCode2": "MH",
    "CountryShort": "MARSHALL ISLANDS",
    "CountryCode": "+692 (Marshall Islands)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Mauritania",
    "ISOCode3": "MRT",
    "ISOCode2": "MR",
    "CountryShort": "MAURITANIA",
    "CountryCode": "+222 (Mauritania)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Mauritius",
    "ISOCode3": "MUS",
    "ISOCode2": "MU",
    "CountryShort": "MAURITIUS",
    "CountryCode": "+230 (Mauritius)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Mexico",
    "ISOCode3": "MEX",
    "ISOCode2": "MX",
    "CountryShort": "MEXICO",
    "CountryCode": "+52 (Mexico)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Micronesia",
    "ISOCode3": "FSM",
    "ISOCode2": "FM",
    "CountryShort": "MICRONESIA",
    "CountryCode": "+691 (Micronesia)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Moldova",
    "ISOCode3": "MDA",
    "ISOCode2": "MD",
    "CountryShort": "MOLDOVA",
    "CountryCode": "+373 (Moldova)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Monaco",
    "ISOCode3": "MCO",
    "ISOCode2": "MC",
    "CountryShort": "MONACO",
    "CountryCode": "+377 (Monaco)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Mongolia",
    "ISOCode3": "MNG",
    "ISOCode2": "MN",
    "CountryShort": "MONGOLIA",
    "CountryCode": "+976 (Mongolia)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Montenegro",
    "ISOCode3": "MNE",
    "ISOCode2": "ME",
    "CountryShort": "MONTENEGRO",
    "CountryCode": "+382 (Montenegro)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Morocco",
    "ISOCode3": "MAR",
    "ISOCode2": "MA",
    "CountryShort": "MOROCCO",
    "CountryCode": "+212 (Morocco)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Mozambique",
    "ISOCode3": "MOZ",
    "ISOCode2": "MZ",
    "CountryShort": "MOZAMBIQUE",
    "CountryCode": "+258 (Mozambique)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Myanmar",
    "ISOCode3": "MMR",
    "ISOCode2": "MM",
    "CountryShort": "MYANMAR",
    "CountryCode": "+95 (Myanmar)",
    "Regex": /^\d{7,9}$/
  },
  {
    "FullName": "Namibia",
    "ISOCode3": "NAM",
    "ISOCode2": "NA",
    "CountryShort": "NAMIBIA",
    "CountryCode": "+264 (Namibia)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Nauru",
    "ISOCode3": "NRU",
    "ISOCode2": "NR",
    "CountryShort": "NAURU",
    "CountryCode": "+674 (Nauru)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Nepal",
    "ISOCode3": "NPL",
    "ISOCode2": "NP",
    "CountryShort": "NEPAL",
    "CountryCode": "+977 (Nepal)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Netherlands",
    "ISOCode3": "NLD",
    "ISOCode2": "NL",
    "CountryShort": "NETHERLANDS",
    "CountryCode": "+31 (Netherlands)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "New Zealand",
    "ISOCode3": "NZL",
    "ISOCode2": "NZ",
    "CountryShort": "NEW ZEALAND",
    "CountryCode": "+64 (New Zealand)",
    "Regex": /^\d{8,9}$/
  },
  {
    "FullName": "Nicaragua",
    "ISOCode3": "NIC",
    "ISOCode2": "NI",
    "CountryShort": "NICARAGUA",
    "CountryCode": "+505 (Nicaragua)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Niger",
    "ISOCode3": "NER",
    "ISOCode2": "NE",
    "CountryShort": "NIGER",
    "CountryCode": "+227 (Niger)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Nigeria",
    "ISOCode3": "NGA",
    "ISOCode2": "NG",
    "CountryShort": "NIGERIA",
    "CountryCode": "+234 (Nigeria)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Norway",
    "ISOCode3": "NOR",
    "ISOCode2": "NO",
    "CountryShort": "NORWAY",
    "CountryCode": "+47 (Norway)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Oman",
    "ISOCode3": "OMN",
    "ISOCode2": "OM",
    "CountryShort": "OMAN",
    "CountryCode": "+968 (Oman)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Pakistan",
    "ISOCode3": "PAK",
    "ISOCode2": "PK",
    "CountryShort": "PAKISTAN",
    "CountryCode": "+92 (Pakistan)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Palau",
    "ISOCode3": "PLW",
    "ISOCode2": "PW",
    "CountryShort": "PALAU",
    "CountryCode": "+680 (Palau)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Panama",
    "ISOCode3": "PAN",
    "ISOCode2": "PA",
    "CountryShort": "PANAMA",
    "CountryCode": "+507 (Panama)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Papua New Guinea",
    "ISOCode3": "PNG",
    "ISOCode2": "PG",
    "CountryShort": "PAPUA NEW GUINEA",
    "CountryCode": "+675 (Papua New Guinea)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Paraguay",
    "ISOCode3": "PRY",
    "ISOCode2": "PY",
    "CountryShort": "PARAGUAY",
    "CountryCode": "+595 (Paraguay)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Peru",
    "ISOCode3": "PER",
    "ISOCode2": "PE",
    "CountryShort": "PERU",
    "CountryCode": "+51 (Peru)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Philippines",
    "ISOCode3": "PHL",
    "ISOCode2": "PH",
    "CountryShort": "PHILIPPINES",
    "CountryCode": "+63 (Philippines)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Poland",
    "ISOCode3": "POL",
    "ISOCode2": "PL",
    "CountryShort": "POLAND",
    "CountryCode": "+48 (Poland)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Portugal",
    "ISOCode3": "PRT",
    "ISOCode2": "PT",
    "CountryShort": "PORTUGAL",
    "CountryCode": "+351 (Portugal)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Qatar",
    "ISOCode3": "QAT",
    "ISOCode2": "QA",
    "CountryShort": "QATAR",
    "CountryCode": "+974 (Qatar)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Romania",
    "ISOCode3": "ROU",
    "ISOCode2": "RO",
    "CountryShort": "ROMANIA",
    "CountryCode": "+40 (Romania)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Russia",
    "ISOCode3": "RUS",
    "ISOCode2": "RU",
    "CountryShort": "RUSSIA",
    "CountryCode": "+7 (Russia)",
    "Regex": /^\d{10,11}$/
  },
  {
    "FullName": "Rwanda",
    "ISOCode3": "RWA",
    "ISOCode2": "RW",
    "CountryShort": "RWANDA",
    "CountryCode": "+250 (Rwanda)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Saint Kitts and Nevis",
    "ISOCode3": "KNA",
    "ISOCode2": "KN",
    "CountryShort": "SAINT KITTS AND NEVIS",
    "CountryCode": "+1-869 (Saint Kitts and Nevis)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Saint Lucia",
    "ISOCode3": "LCA",
    "ISOCode2": "LC",
    "CountryShort": "SAINT LUCIA",
    "CountryCode": "+1-758 (Saint Lucia)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Saint Vincent and the Grenadines",
    "ISOCode3": "VCT",
    "ISOCode2": "VC",
    "CountryShort": "SAINT VINCENT AND THE GRENADINES",
    "CountryCode": "+1-784 (Saint Vincent and the Grenadines)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Samoa",
    "ISOCode3": "WSM",
    "ISOCode2": "WS",
    "CountryShort": "SAMOA",
    "CountryCode": "+685 (Samoa)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "San Marino",
    "ISOCode3": "SMR",
    "ISOCode2": "SM",
    "CountryShort": "SAN MARINO",
    "CountryCode": "+378 (San Marino)",
    "Regex": /^\d{10}$/
  },
  {
    "FullName": "Sao Tome and Principe",
    "ISOCode3": "STP",
    "ISOCode2": "ST",
    "CountryShort": "SAO TOME AND PRINCIPE",
    "CountryCode": "+239 (Sao Tome and Principe)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Saudi Arabia",
    "ISOCode3": "SAU",
    "ISOCode2": "SA",
    "CountryShort": "SAUDI ARABIA",
    "CountryCode": "+966 (Saudi Arabia)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Senegal",
    "ISOCode3": "SEN",
    "ISOCode2": "SN",
    "CountryShort": "SENEGAL",
    "CountryCode": "+221 (Senegal)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Serbia",
    "ISOCode3": "SRB",
    "ISOCode2": "RS",
    "CountryShort": "SERBIA",
    "CountryCode": "+381 (Serbia)",
    "Regex": /^\d{6,12}$/
  },
  {
    "FullName": "Seychelles",
    "ISOCode3": "SYC",
    "ISOCode2": "SC",
    "CountryShort": "SEYCHELLES",
    "CountryCode": "+248 (Seychelles)",
    "Regex": /^\d{7}$/
  },
  {
    "FullName": "Sierra Leone",
    "ISOCode3": "SLE",
    "ISOCode2": "SL",
    "CountryShort": "SIERRA LEONE",
    "CountryCode": "+232 (Sierra Leone)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Singapore",
    "ISOCode3": "SGP",
    "ISOCode2": "SG",
    "CountryShort": "SINGAPORE",
    "CountryCode": "+65 (Singapore)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Slovakia",
    "ISOCode3": "SVK",
    "ISOCode2": "SK",
    "CountryShort": "SLOVAKIA",
    "CountryCode": "+421 (Slovakia)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Slovenia",
    "ISOCode3": "SVN",
    "ISOCode2": "SI",
    "CountryShort": "SLOVENIA",
    "CountryCode": "+386 (Slovenia)",
    "Regex": /^\d{8}$/
  },
  {
    "FullName": "Solomon Islands",
    "ISOCode3": "SLB",
    "ISOCode2": "SB",
    "CountryShort": "SOLOMON ISLANDS",
    "CountryCode": "+677 (Solomon Islands)",
    "Regex": /^\d{5}$/
  },
  {
    "FullName": "Somalia",
    "ISOCode3": "SOM",
    "ISOCode2": "SO",
    "CountryShort": "SOMALIA",
    "CountryCode": "+252 (Somalia)",
    "Regex": /^\d{7,8}$/
  },
  {
    "FullName": "South Africa",
    "ISOCode3": "ZAF",
    "ISOCode2": "ZA",
    "CountryShort": "SOUTH AFRICA",
    "CountryCode": "+27 (South Africa)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "South Sudan",
    "ISOCode3": "SSD",
    "ISOCode2": "SS",
    "CountryShort": "SOUTH SUDAN",
    "CountryCode": "+211 (South Sudan)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Spain",
    "ISOCode3": "ESP",
    "ISOCode2": "ES",
    "CountryShort": "SPAIN",
    "CountryCode": "+34 (Spain)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Sri Lanka",
    "ISOCode3": "LKA",
    "ISOCode2": "LK",
    "CountryShort": "SRI LANKA",
    "CountryCode": "+94 (Sri Lanka)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Sudan",
    "ISOCode3": "SDN",
    "ISOCode2": "SD",
    "CountryShort": "SUDAN",
    "CountryCode": "+249 (Sudan)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Suriname",
    "ISOCode3": "SUR",
    "ISOCode2": "SR",
    "CountryShort": "SURINAME",
    "CountryCode": "+597 (Suriname)",
    "Regex": /^\d{6}$/
  },
  {
    "FullName": "Sweden",
    "ISOCode3": "SWE",
    "ISOCode2": "SE",
    "CountryShort": "SWEDEN",
    "CountryCode": "+46 (Sweden)",
    "Regex": /^\d{9,11}$/
  },
  {
    "FullName": "Switzerland",
    "ISOCode3": "CHE",
    "ISOCode2": "CH",
    "CountryShort": "SWITZERLAND",
    "CountryCode": "+41 (Switzerland)",
    "Regex": /^\d{9}$/
  },
  {
    "FullName": "Syria",
    "ISOCode3": "SYR",
    "ISOCode2": "SY",
    "CountryShort": "SYRIA",
    "CountryCode": "+963 (SYRIA)",
    "Regex": /^\d{9}$/
  },
  {
    FullName: "Taiwan",
    ISOCode3: "TWN",
    ISOCode2: "TW",
    CountryShort: "TAIWAN",
    CountryCode: "+886 (Taiwan)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Tajikistan",
    ISOCode3: "TJK",
    ISOCode2: "TJ",
    CountryShort: "TAJIKISTAN",
    CountryCode: "+992 (Tajikistan)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Tanzania",
    ISOCode3: "TZA",
    ISOCode2: "TZ",
    CountryShort: "TANZANIA",
    CountryCode: "+255 (Tanzania)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Thailand",
    ISOCode3: "THA",
    ISOCode2: "TH",
    CountryShort: "THAILAND",
    CountryCode: "+66 (Thailand)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Togo",
    ISOCode3: "TGO",
    ISOCode2: "TG",
    CountryShort: "TOGO",
    CountryCode: "+228 (Togo)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Tonga",
    ISOCode3: "TON",
    ISOCode2: "TO",
    CountryShort: "TONGA",
    CountryCode: "+676 (Tonga)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Trinidad and Tobago",
    ISOCode3: "TTO",
    ISOCode2: "TT",
    CountryShort: "TRINIDAD AND TOBAGO",
    CountryCode: "+1 (Trinidad and Tobago)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Tunisia",
    ISOCode3: "TUN",
    ISOCode2: "TN",
    CountryShort: "TUNISIA",
    CountryCode: "+216 (Tunisia)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Turkey",
    ISOCode3: "TUR",
    ISOCode2: "TR",
    CountryShort: "TURKEY",
    CountryCode: "+90 (Turkey)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Turkmenistan",
    ISOCode3: "TKM",
    ISOCode2: "TM",
    CountryShort: "TURKMENISTAN",
    CountryCode: "+993 (Turkmenistan)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Tuvalu",
    ISOCode3: "TUV",
    ISOCode2: "TV",
    CountryShort: "TUVALU",
    CountryCode: "+688 (Tuvalu)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Uganda",
    ISOCode3: "UGA",
    ISOCode2: "UG",
    CountryShort: "UGANDA",
    CountryCode: "+256 (Uganda)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Ukraine",
    ISOCode3: "UKR",
    ISOCode2: "UA",
    CountryShort: "UKRAINE",
    CountryCode: "+380 (Ukraine)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "United Arab Emirates",
    ISOCode3: "ARE",
    ISOCode2: "AE",
    CountryShort: "UNITED ARAB EMIRATES",
    CountryCode: "+971 (United Arab Emirates)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "United Kingdom",
    ISOCode3: "GBR",
    ISOCode2: "GB",
    CountryShort: "UNITED KINGDOM",
    CountryCode: "+44 (United Kingdom)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "United States",
    ISOCode3: "USA",
    ISOCode2: "US",
    CountryShort: "UNITED STATES",
    CountryCode: "+1 (United States)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Uruguay",
    ISOCode3: "URY",
    ISOCode2: "UY",
    CountryShort: "URUGUAY",
    CountryCode: "+598 (Uruguay)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Uzbekistan",
    ISOCode3: "UZB",
    ISOCode2: "UZ",
    CountryShort: "UZBEKISTAN",
    CountryCode: "+998 (Uzbekistan)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Vanuatu",
    ISOCode3: "VUT",
    ISOCode2: "VU",
    CountryShort: "VANUATU",
    CountryCode: "+678 (Vanuatu)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Vatican City",
    ISOCode3: "VAT",
    ISOCode2: "VA",
    CountryShort: "VATICAN CITY",
    CountryCode: "+379 (Vatican City)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Venezuela",
    ISOCode3: "VEN",
    ISOCode2: "VE",
    CountryShort: "VENEZUELA",
    CountryCode: "+58 (Venezuela)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Vietnam",
    ISOCode3: "VNM",
    ISOCode2: "VN",
    CountryShort: "VIETNAM",
    CountryCode: "+84 (Vietnam)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Yemen",
    ISOCode3: "YEM",
    ISOCode2: "YE",
    CountryShort: "YEMEN",
    CountryCode: "+967 (Yemen)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Zambia",
    ISOCode3: "ZMB",
    ISOCode2: "ZM",
    CountryShort: "ZAMBIA",
    CountryCode: "+260 (Zambia)",
    Regex: /^\d{9}$/
  },
  {
    FullName: "Zimbabwe",
    ISOCode3: "ZWE",
    ISOCode2: "ZW",
    CountryShort: "ZIMBABWE",
    CountryCode: "+263 (Zimbabwe)",
    Regex: /^\d{9}$/
  }
];