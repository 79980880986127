import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResponseModel } from '@root/shared/models/base-response.model';
import { environment } from 'src/environments/environment';
import { BlockLoaderModel } from '../models/block-loader.model';
import { LandingRepository } from '../store/landing.repository';
import { AppRepository } from '@root/store/app.repository';
import { OutputPropertiesModel } from '@root/shared/models/output-properties.model';
import { BlockLoaderOutputKeysEnum } from '../models/block-loader-output-keys.enum';
import moment from 'moment';
import { getOS } from '@root/shared/services/utilities.service/utilities.service';
import { OutputKeysRepository } from '@root/shared/store/output-keys/output-keys.repository';
import { AppConfiguration } from '@root/shared/models/app-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class BlockLoaderService {
  constructor(
    private http: HttpClient,
    private blockLoaderRepository: LandingRepository,
    private appRepository: AppRepository,
    private outputKeysRepository: OutputKeysRepository,
    private route: Router
  ) { }

  callBlockLoaderAPI() {
    const appConfiguration = this.appRepository.getAppConfiguration();
    if (appConfiguration) {
      const blockLoaderEndPoint = `${environment.assentifyEndPoint}BlockLoader/GetStep/${appConfiguration.stepId}`
      this.http
        .get<BaseResponseModel<BlockLoaderModel>>(
          blockLoaderEndPoint,
          {
            headers: new HttpHeaders({
              InterceptorShowSpinner: ''
            }),
          }
        )
        .subscribe((result: BaseResponseModel<BlockLoaderModel>) => {
          this.blockLoaderRepository.updateBlockLoader(result.data);
        });
    } else {
      this.route.navigate(['AreYouLost']);
    }
  }

  dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
  }

  async updateBlockLoaderDefaultOutputKeys(blockLoaderData: BlockLoaderModel, appConfiguration: AppConfiguration) {
    blockLoaderData.outputProperties.map((item: OutputPropertiesModel) => {
      const keyword = "OnBoardMe";
      const index = item.key.indexOf(keyword);
      const result = item.key.substring(index);
      switch (result) {
        case BlockLoaderOutputKeysEnum.DeviceName:
          this.addDeviceNameOutputKey(blockLoaderData.outputProperties);
          break;
        case BlockLoaderOutputKeysEnum.UserAgent:
          this.addUserAgentOutputKey(blockLoaderData.outputProperties);
          break;
        case BlockLoaderOutputKeysEnum.InstanceHash:
          this.addInstanceHashOutputKey(blockLoaderData.outputProperties, appConfiguration.instanceHash);
          break;
        case BlockLoaderOutputKeysEnum.TimeStarted:
          this.addTimeStartedOutputKey(blockLoaderData.outputProperties);
          break;
        case BlockLoaderOutputKeysEnum.FlowName:
          this.addFlowNameOutputKey(blockLoaderData.outputProperties, appConfiguration.flowName);
          break;
        case BlockLoaderOutputKeysEnum.Application:
          this.addApplicationOutputKey(blockLoaderData.outputProperties, appConfiguration.applicationId);
          break;
        case BlockLoaderOutputKeysEnum.Interaction:
          this.addInteractionOutputKey(blockLoaderData.outputProperties, appConfiguration.flowInstanceId);
          break;
        default:
          break;
      }
    });
  }

  addApplicationOutputKey(outputProperties: OutputPropertiesModel[], applicationId: string) {
    const instanceHashKey = this.getOutputKey(BlockLoaderOutputKeysEnum.Application, outputProperties);
    this.outputKeysRepository.addOutputProperty(instanceHashKey, applicationId);
  }

  addInteractionOutputKey(outputProperties: OutputPropertiesModel[], flowInstanceId: string) {
    const instanceHashKey = this.getOutputKey(BlockLoaderOutputKeysEnum.Interaction, outputProperties);
    this.outputKeysRepository.addOutputProperty(instanceHashKey, `APP-${flowInstanceId}`);
  }

  addFlowNameOutputKey(outputProperties: OutputPropertiesModel[], flowName: string) {
    const instanceHashKey = this.getOutputKey(BlockLoaderOutputKeysEnum.FlowName, outputProperties);
    this.outputKeysRepository.addOutputProperty(instanceHashKey, flowName);
  }

  addTimeStartedOutputKey(outputProperties: OutputPropertiesModel[]) {
    const timeStartedKey = this.getOutputKey(BlockLoaderOutputKeysEnum.TimeStarted, outputProperties);
    this.outputKeysRepository.addOutputProperty(timeStartedKey, moment().format('DD/MM/YYYY HH:mm:ss'));
  }

  addUserAgentOutputKey(outputProperties: OutputPropertiesModel[]) {
    const userAgentKey = this.getOutputKey(BlockLoaderOutputKeysEnum.UserAgent, outputProperties);
    this.outputKeysRepository.addOutputProperty(userAgentKey, window.navigator.userAgent.toLowerCase());
  }

  addDeviceNameOutputKey(outputProperties: OutputPropertiesModel[]) {
    const deviceNameKey = this.getOutputKey(BlockLoaderOutputKeysEnum.DeviceName, outputProperties);
    this.outputKeysRepository.addOutputProperty(deviceNameKey, getOS());
  }

  addInstanceHashOutputKey(outputProperties: OutputPropertiesModel[], instanceHash: string) {
    const instanceHashKey = this.getOutputKey(BlockLoaderOutputKeysEnum.InstanceHash, outputProperties);
    this.outputKeysRepository.addOutputProperty(instanceHashKey, instanceHash);
  }

  getOutputKey(key: string, outputProperties: OutputPropertiesModel[]) {
    return outputProperties.find((item: OutputPropertiesModel) => item.key.includes(key))?.key
  }

}
