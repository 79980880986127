import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MessageType } from '../../models/enums/message-type';
import { CommunicationRepository } from '../../store/communication.repository';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { ChatService } from '../../services/chat.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { isSpinning$ } from '@root/store/app.store';
import { Message } from '../../models/message.model';
import { AppRepository } from '@root/store/app.repository';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { UIService } from '@root/shared/services/ui-service/ui-service.service';

@Component({
  selector: 'app-send-message',
  standalone: true,
  imports: [
    MatButtonModule,
    MatInputModule,
    PickerComponent,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    NgClickOutsideDirective
  ],
  templateUrl: './send-message.component.html',
  styleUrl: './send-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMessageComponent extends BaseComponent implements OnInit {
  @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;
  isMobile$: Observable<boolean> = this.uIService.isMobile$;
  isSpinning$: Observable<boolean> = isSpinning$;
  mediaRecorder: any;
  audioBlob: Blob | null = null;
  showEmoji = false;
  audioChunks: BlobPart[] = [];

  recordingTime: string = '00:00';
  isRecording = false;
  isStopRecording = false;
  uploadedImage: any;
  uploadedFile: any;
  uploadedVideo: any;
  messageText = '';
  imageMessageLink: string;
  isMicrophoneAllowed: boolean = false;

  fileName: string;
  interval: any;

  constructor(private uIService: UIService,
    private cdr: ChangeDetectorRef,
    private chatService: ChatService,
    private appRepository: AppRepository,
    private communicationRepository: CommunicationRepository
  ) {
    super();
  }
  private audioContext: AudioContext = new AudioContext();

  get imageExtensions() {
    return ['.jpeg', '.png', '.svg', '.jpg'].join(', ');
  }

  get fileExtensions() {
    return ['.pdf', '.word', '.excl'].join(', ');
  }

  get videoExtensions() {
    return [
      '.mp4',
      '.mov',
      '.wmv',
      '.webm',
      '.avi',
      '.flv',
      '.mkv',
      '.mts',
    ].join(', ');
  }

  async ngOnInit(): Promise<void> {
    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume();
    }

    await this.checkMicrophoneAccess();
  }

  onImageUploaded(event: any) {
    this.uploadedImage = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageMessageLink = e.target.result as string;
      this.cdr.detectChanges();
    };
    reader.readAsDataURL(this.uploadedImage);
    this.onDeleteSelectedFile();
  }

  onVideoUploaded(event: any) {
    this.uploadedVideo = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageMessageLink = e.target.result as string;
      this.cdr.detectChanges();
    };
    reader.readAsDataURL(this.uploadedVideo);
    this.onDeleteSelectedFile();
  }

  onFileUploaded(event: any) {
    this.uploadedFile = event.target.files[0];
    this.fileName = this.uploadedFile.name;
    this.onDeleteSelectedImage();
  }

  startRecording() {
    this.isRecording = true;
    this.updateRecordingTime();

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.start();

        this.mediaRecorder.ondataavailable = (e: any) => {
          this.audioChunks.push(e.data);
        };

        this.mediaRecorder.onstop = async () => {
          // this.audioBlob = new Blob(this.audioChunks, { type: this.mediaRecorder.mimeType });
          const audioData = await new Blob(this.audioChunks).arrayBuffer();
          const audioBuffer = await this.audioContext.decodeAudioData(
            audioData
          );
          this.audioBlob = bufferToWave(audioBuffer, audioBuffer.length);
          this.playRecording();
        };
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  }

  stopRecording() {
    if (this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.stop();
      this.isStopRecording = true;
      this.recordingTime = '00:00';
      clearInterval(this.interval);
    }
  }

  playRecording() {
    if (this.audioBlob) {
      const audioUrl = URL.createObjectURL(this.audioBlob);
      this.audioPlayer.nativeElement.src = audioUrl;
      this.audioPlayer.nativeElement.load();
      this.cdr.detectChanges();
    }
  }

  onDeleteRecord() {
    this.recordingTime = '00:00';
    this.audioBlob = null;
    this.audioChunks = [];
    this.isStopRecording = false;
    this.isRecording = false;
    clearInterval(this.interval);
  }

  onSendMessage(event: any) {
    const selectedGroup = this.communicationRepository.getValue().selectedGroup.id;
    const tenantIdentifier = this.appRepository.getAppConfiguration().tenantId;
    const messageData: Message = {
      content: this.messageText,
      userId: this.appRepository.getAppConfiguration().instanceId,
      groupId: selectedGroup,
      isAdminMessage: false,
      fileName: this.fileName,
      tenantIdentifier: tenantIdentifier,
      messageType: MessageType.Text,
    };

    if (this.audioBlob && this.isStopRecording) {
      messageData.messageType = MessageType.Voice;
      messageData.fileContent = this.audioBlob;
    } else if (this.uploadedImage) {
      messageData.messageType = MessageType.Image;
      messageData.fileContent = this.uploadedImage;
    } else if (this.uploadedVideo) {
      messageData.messageType = MessageType.Video;
      messageData.fileContent = this.uploadedVideo;
    } else if (this.uploadedFile) {
      messageData.messageType = MessageType.File;
      messageData.fileContent = this.uploadedFile;
    } else if (this.messageText?.length > 0) {
      messageData.messageType = MessageType.Text;
    }

    if (
      messageData.messageType !== MessageType.Text ||
      (messageData.messageType == MessageType.Text &&
        this.messageText?.length > 0)
    )
      this.chatService.sendMessage(messageData);
    event.preventDefault();
    // Reset variables
    this.uploadedImage = null;
    this.messageText = '';
    this.audioBlob = null;
    this.audioChunks = [];
    this.isStopRecording = false;
    this.isRecording = false;
    this.imageMessageLink = null;
    this.uploadedFile = null;
    this.uploadedVideo = null;
    this.recordingTime = '00:00';
    clearInterval(this.interval);
  }

  private updateRecordingTime() {
    const startTime = Date.now();
    this.interval = setInterval(() => {
      if (!this.isStopRecording) {
        const elapsedTime = Date.now() - startTime;
        this.recordingTime = this.formatTime(elapsedTime);
        this.cdr.detectChanges();
      }
    }, 1000);
  }

  private formatTime(milliseconds: number): string {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  onshowEmoji() {
    this.showEmoji = true;
    this.cdr.detectChanges();
  }

  onSelectEmoji(data: any) {
    this.showEmoji = false;
    this.messageText = this.messageText + data.emoji.native;
    this.cdr.detectChanges();
  }

  onClickedOutside(_e: Event) {
    this.showEmoji = false;
  }

  onDeleteSelectedImage() {
    this.imageMessageLink = null;
    this.uploadedImage = null;
  }

  async checkMicrophoneAccess() {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      this.isMicrophoneAllowed = true;
      this.cdr.detectChanges();
    } catch (error) {
      this.isMicrophoneAllowed = false;
    }
  }

  onDeleteSelectedFile() {
    this.uploadedFile = null;
  }
}

export function bufferToWave(abuffer: any, len: number) {
  let numOfChan = abuffer.numberOfChannels,
    length = len * numOfChan * 2 + 44,
    buffer = new ArrayBuffer(length),
    view = new DataView(buffer),
    channels = [],
    i,
    sample,
    offset = 0,
    pos = 0;

  // write WAVE header
  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"

  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(abuffer.sampleRate);
  setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit (hardcoded in this demo)

  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 8); // chunk length

  // write interleaved data
  for (i = 0; i < abuffer.numberOfChannels; i++)
    channels.push(abuffer.getChannelData(i));

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) {
      // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true); // write 16-bit sample
      pos += 2;
    }
    offset++; // next source sample
  }

  // create Blob
  return new Blob([buffer], { type: 'audio/wav' });

  function setUint16(data: any) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data: any) {
    view.setUint32(pos, data, true);
    pos += 4;
  }
}
