import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PdfViewerModule, MatDialogModule, MatIconModule, CommonModule]
})
export class DocumentViewerComponent extends BaseComponent implements OnInit {
  document: string;


  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: string,
    private dialog: MatDialog) { super(); }

  ngOnInit(): void {
    this.document = this.dialogData;
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.dialog.closeAll();
    }
  }

}
