import { IdCardRotationModel } from './../model/id-card-rotation.model';
import { LabelModel } from './../model/label.model';
import { InitializingModel } from './../model/initializing.model';
import { InjectionToken } from "@angular/core";
import { createStore, select, withProps } from "@ngneat/elf";
import { QualityProcessingMessageModel } from "../model/quality-processing-message.model";
import { ManualCaptureMessage } from "../model/manual-capture-message.model";
import { TurnOnCameraModel } from "../model/turn-on-camera.model";
import { ResultAnimationModel } from "../model/result-animation.model";
import { ResultCompletedModel } from "../model/result-completed,model";
import { SuccessImageModel } from "../model/success-image.model";
import { FrontAndBackLabelStatusModel } from "../model/front-and-back-label-status.model";
import { SignalRConnectionFailedModel } from '../model/signalr-connection-failed.model';
import { OpenCameraFailedModel } from '../model/open-camera-failed.model';
import { ProcessingModel } from '../model/processing.model';
import { RetryMessageModel } from '../model/retry-message.model';
import { IdCardGuideModel } from '../model/id-card-guide.model';
import { ExpiredPassportModel } from '../model/expired-passport.model';
import { WrongTemplateModel } from '../model/wrong-template.model';
import { HoldSteadyModel } from '../model/hold-steady-model';
import { CardScanGuidance } from '../model/card-scan-guidance.model';
import { RealIdModel } from '../model/real-id.model';
import { MisMatchModel } from '../model/mismatch';
import { CameraPermissionFailedModel } from '../model/camera-permission-failed.model';
import { GettingThingsReadyModel } from '../model/getting-things-ready.model';
import { AutoCaptureOffModel } from '../model/auto-capture-off.model';
import { BlurredImageModel } from '../model/blurred-image.model';

export interface BasicDetectionModel {
    blurredImageModel: BlurredImageModel;
    manualCaptureMessage: ManualCaptureMessage;
    retryMessageModel: RetryMessageModel;
    resultAnimationModel: ResultAnimationModel;
    processingModel: ProcessingModel;
    idCardGuideModel: IdCardGuideModel;
    resultCompletedModel: ResultCompletedModel;
    qualityProcessingMessage: QualityProcessingMessageModel;
    turnOnCameraModel: TurnOnCameraModel;
    hasPredictions: boolean;
    processQualityCheck: boolean;
    successImageModel: SuccessImageModel;
    initializingModel: InitializingModel;
    labelModel: LabelModel;
    frontAndBackLabelStatusModel: FrontAndBackLabelStatusModel;
    idCardRotationModel: IdCardRotationModel;
    signalRConnectionFailedModel: SignalRConnectionFailedModel;
    openCameraFailedModel: OpenCameraFailedModel;
    cameraPermissionFailedModel: CameraPermissionFailedModel;
    infoText: string;
    expiredPassportModel: ExpiredPassportModel;
    wrongTemplateModel: WrongTemplateModel;
    colorBoundingBox: string;
    holdSteadyModel: HoldSteadyModel;
    cardScanGuidance: CardScanGuidance;
    realId: RealIdModel;
    misMatchModel: MisMatchModel;
    gettingThingsReadyModel: GettingThingsReadyModel;
    autoCaptureOffModel: AutoCaptureOffModel;
}

const store = createStore(
    {
        name: 'basic-detection-store'
    },
    withProps<BasicDetectionModel>({
        retryMessageModel: null,
        successImageModel: null,
        processQualityCheck: null,
        processingModel: null,
        idCardGuideModel: null,
        qualityProcessingMessage: null,
        manualCaptureMessage: null,
        resultAnimationModel: null,
        resultCompletedModel: null,
        turnOnCameraModel: null,
        hasPredictions: false,
        initializingModel: null,
        labelModel: null,
        frontAndBackLabelStatusModel: null,
        idCardRotationModel: null,
        openCameraFailedModel: null,
        signalRConnectionFailedModel: null,
        infoText: null,
        expiredPassportModel: null,
        wrongTemplateModel: null,
        colorBoundingBox: null,
        holdSteadyModel: null,
        cardScanGuidance: null,
        realId: null,
        misMatchModel: null,
        cameraPermissionFailedModel: null,
        gettingThingsReadyModel: null,
        autoCaptureOffModel: null,
        blurredImageModel: null
    })
);

export const autoCaptureOffModel$ = store.pipe(select(({ autoCaptureOffModel }) => autoCaptureOffModel));
export const blurredImageModel$ = store.pipe(select(({ blurredImageModel }) => blurredImageModel));
export const gettingThingsReadyModel$ = store.pipe(select(({ gettingThingsReadyModel }) => gettingThingsReadyModel));
export const cameraPermissionFailedModel$ = store.pipe(select(({ cameraPermissionFailedModel }) => cameraPermissionFailedModel));
export const misMatchModel$ = store.pipe(select(({ misMatchModel }) => misMatchModel));
export const realId$ = store.pipe(select(({ realId }) => realId));
export const expiredPassportModel$ = store.pipe(select(({ expiredPassportModel }) => expiredPassportModel));
export const infoText$ = store.pipe(select(({ infoText }) => infoText));
export const idCardGuideModel$ = store.pipe(select(({ idCardGuideModel }) => idCardGuideModel));
export const openCameraFailedModel$ = store.pipe(select(({ openCameraFailedModel }) => openCameraFailedModel));
export const retryMessageModel$ = store.pipe(select(({ retryMessageModel }) => retryMessageModel));
export const processingModel$ = store.pipe(select(({ processingModel }) => processingModel));
export const processQualityCheck$ = store.pipe(select(({ processQualityCheck }) => processQualityCheck));
export const signalRConnectionFailedModel$ = store.pipe(select(({ signalRConnectionFailedModel }) => signalRConnectionFailedModel));
export const hasPredictions$ = store.pipe(select(({ hasPredictions }) => hasPredictions));
export const qualityProcessingMessage$ = store.pipe(select(({ qualityProcessingMessage }) => qualityProcessingMessage));
export const manualCaptureMessage$ = store.pipe(select(({ manualCaptureMessage }) => manualCaptureMessage));
export const turnOnCameraModel$ = store.pipe(select(({ turnOnCameraModel }) => turnOnCameraModel));
export const resultAnimationModel$ = store.pipe(select(({ resultAnimationModel }) => resultAnimationModel));
export const resultCompletedModel$ = store.pipe(select(({ resultCompletedModel }) => resultCompletedModel));
export const successImageModel$ = store.pipe(select(({ successImageModel }) => successImageModel));
export const initializingModel$ = store.pipe(select(({ initializingModel }) => initializingModel));
export const labelModel$ = store.pipe(select(({ labelModel }) => labelModel));
export const idCardRotationModel$ = store.pipe(select(({ idCardRotationModel }) => idCardRotationModel));
export const frontAndBackLabelStatusModel$ = store.pipe(select(({ frontAndBackLabelStatusModel }) => frontAndBackLabelStatusModel));
export const wrongTemplateModel$ = store.pipe(select(({ wrongTemplateModel }) => wrongTemplateModel));
export const holdSteadyModel$ = store.pipe(select(({ holdSteadyModel }) => holdSteadyModel));
export const cardScanGuidance$ = store.pipe(select(({ cardScanGuidance }) => cardScanGuidance));

export type BasicDetectionStore = typeof store;
export const BASIC_DETECTION_STORE = new InjectionToken<ReturnType<typeof createStore>>('Injection Token For Animation', {
    providedIn: 'root',
    factory: (): BasicDetectionStore => store,
});
