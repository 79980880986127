import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppRepository } from '@root/store/app.repository';
import { AppConfiguration } from '../models/app-configuration.model';
import { NotificationRepository } from '../notifications/store/notification.repository';

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
  constructor(
    private appRepository: AppRepository,
    private notificationRepository: NotificationRepository
  ) { }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let request: HttpRequest<unknown>;
    request = getAcceptLanguageHeaders(req);

    if (req?.headers.has('InterceptorSkipConfigurationHeader')) {
      const headers = req?.headers.delete('InterceptorSkipConfigurationHeader');
      request = req?.clone({ headers });
    } else {
      const appConfiguration = this.appRepository.getAppConfiguration();
      const firebaseToken = this.notificationRepository.getFirebaseToken();
      if (appConfiguration) {
        request = getConfigurationsHeaders(req, appConfiguration, firebaseToken);
      }
    }
    return next.handle(request);
  }
}

const getAcceptLanguageHeaders = (req: HttpRequest<unknown>) => {

  const lang = '';
  const request: HttpRequest<unknown> = lang
    ? req.clone({
      setHeaders: { 'Accept-Language': lang },
    })
    : req;
  return request;
};

const getConfigurationsHeaders = (
  req: HttpRequest<unknown>,
  appConfiguration: AppConfiguration,
  firebaseToken: string
) => {
  let headers: any = {
    'X-Tenant-Identifier': appConfiguration.tenantId,
    'X-Block-Identifier': appConfiguration.blockId,
    'X-Flow-Identifier': appConfiguration.flowIdentifier,
    'X-Instance-Id': appConfiguration.instanceId,
    'X-Flow-instance-Id': appConfiguration.flowInstanceId,
    'X-Instance-Hash': appConfiguration.instanceHash,
    'X-User-Agent': window.navigator.userAgent.toLowerCase(),
    'Accept-Language': localStorage?.getItem('language') ?? 'en'
  };

  if (firebaseToken) {
    headers = { ...headers, 'X-FCM-Token': firebaseToken };
  }
  const request = req?.clone({
    setHeaders: headers,
  });
  return request;
};
