import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../base-component/base-component';
import { showSkeleton$ } from '@root/store/app.store';

@Component({
  selector: 'app-raised-button',
  templateUrl: './raisedButton.component.html',
  styleUrls: ['./raisedButton.component.scss'],
  standalone: true,
  imports: []
})
export class RaisedButtonComponent extends BaseComponent implements OnInit {
  @Input() text: string | undefined;
  @Input() backgroundColor: string | undefined;
  @Input() isDisabled = false;

  @Output() triggerClickEvent: EventEmitter<void> = new EventEmitter<void>();

  showSkeleton = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      showSkeleton$.subscribe((data: boolean) => {
        this.showSkeleton = data;
      })
    );
  }

  onClick() {
    this.triggerClickEvent.emit();
  }

  updateButtonText(text: string) {
    this.text = text;
  }
}
