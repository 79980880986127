import { animate, state, style, transition, trigger } from "@angular/animations";

export const logoFadeInAnimations = trigger('logoFadeIn', [
    state(
        'true',
        style({
            opacity: 1,
        })
    ),
    state(
        'false',
        style({
            opacity: 0,
        })
    ),
    transition('false => true', animate('1000ms ease-in')),
    transition('true => false', animate('1ms ease-in')),
]);

export const logoAccentFadeInAnimations = trigger('logoAccentFadeIn', [
    state(
        'true',
        style({
            height: '100%',
            opacity: 1,
        })
    ),
    state(
        'false',
        style({
            opacity: 0,
        })
    ),
    transition('false => true', animate('1000ms ease-in')),
    transition('true => false', animate('1ms ease-in')),
]);