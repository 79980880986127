export const getFormData = (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key as keyof typeof data])) {
            Array.from(data[key as keyof typeof data]).forEach((e: any) => {
                formData.append(key, getFormDataValue(e));
            })
        }
        else {
            formData.append(key, getFormDataValue(data[key as keyof typeof data]));
        }
    });
    return formData;
};

export const getFormDataValue = (value: any) => {
    if (typeof value === 'number') {
        return JSON.stringify(value);
    }
    return value ?? '';
};
