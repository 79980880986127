import { Injectable, Inject } from '@angular/core';
import { DATA_SOURCE_STORE, DataSourceStore } from './data-source.store';
import { DataSourceValues } from '@root/shared/models/data-source-values.model';

@Injectable({
    providedIn: 'root',
})
export class DataSourceRepository {

    constructor(@Inject(DATA_SOURCE_STORE) private dataSourceStore: DataSourceStore) { }

    reset() {
        this.updateDataSourceValues(undefined);
    }

    updateDataSourceValues(dataSourceValues: DataSourceValues[]) {
        this.dataSourceStore.update((state) => ({
            ...state,
            dataSourceValues,
        }));
    }

    getDataSourceValues(): DataSourceValues[] { 
        return this.dataSourceStore.getValue().dataSourceValues;
    }

    addDataSourceValue(dataSourceValue: DataSourceValues) {
        const dataSourceList = this.dataSourceStore.getValue().dataSourceValues;
        const index = dataSourceList.findIndex(dataSourceItem => dataSourceItem.elementIdentifier === dataSourceValue.elementIdentifier);
        dataSourceList[index] = dataSourceValue;
        this.updateDataSourceValues(undefined);
        this.updateDataSourceValues(dataSourceList);
    }

    clearDataSourceItems(elementIdentifier: string) {
        const dataSourceList = this.dataSourceStore.getValue().dataSourceValues;
        const index = dataSourceList.findIndex(dataSourceItem => dataSourceItem.elementIdentifier === elementIdentifier);
        dataSourceList[index].items = [];
        this.updateDataSourceValues(undefined);
        this.updateDataSourceValues(dataSourceList);
    }
}
