<div class="flex flex-col h-full w-fit justify-center content-center gap-[1px]">
    <div class="flex h-full w-full justify-center items-center">
        <div class="flex rounded-full justify-center card-border background-body-color items-center p-2 sm:p-0"
            [ngClass]="
            {
                'h-10 w-10 sm:h-10 sm:w-10 2xl:h-12 2xl:w-12': isActive ,
                'h-8 w-8 sm:h-8 sm:w-8 2xl:h-10 2xl:w-10': isNext || isPrevious,
            }" (click)="onStepSelected()" (keydown.enter)="onStepSelected()" tabindex="0" role="button">
            <mat-icon class="text-primary-color" [svgIcon]="getIcon()" [ngStyle]="getIconColor()"></mat-icon>
        </div>
    </div>
</div>