<div [dir]="pageDirection$|async" [class.dialog-confirmation-simple]="!data.description" [class.dialog-confirmation-complex]="data.description">
    <div>
        <div mat-dialog-title class="flex items-center pt-4">
            <span [ngClass]="data.iconCssClasses" class="ltr:mr-4 rtl:ml-4 flex items-center">
                <mat-icon>{{ data.icon ?? close }}</mat-icon>
            </span>
            <h2 translate class="text-lg font-bold">{{ data.title }}</h2>
        </div>
        <div class="ltr:ml-10 rtl:mr-10">
            <p translate class="mb-4">{{ data.description }}</p>
        </div>
    </div>

    <div mat-dialog-actions class="flex justify-end w-full items-center pb-4">
        <button type="button" style="margin-right: 10px;" mat-raised-button [color]="data.actionButtonsColor ?? 'accent'" mat-dialog-close>
            <span class="text-white">{{ data.cancelText | translate }}</span>
        </button>
        <button class="mr-3" type="button" mat-stroked-button [color]="data.actionButtonsColor ?? 'accent'" (click)="confirm()">
            {{ data.confirmText | translate }}
        </button>
    </div>
</div>