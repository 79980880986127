import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-empty-message',
  standalone: true,
  imports: [NgClass],
  templateUrl: './empty-message.component.html',
  styleUrl: './empty-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyMessageComponent {
}
