<div class="flex justify-between items-center p-4">
    <h3 class="text-xl text-accent">Chat</h3>
    <button (click)="onCloseChat()" mat-button mat-dialog-close>
        <span class="material-icons"> close </span>
    </button>
</div>
<div class="flex flex-col overflow-hidden px-4 h-full">
    @if(selectedGroup?.id){ @if(selectedGroup?.messages?.length>0){
    <div #selectedConversation infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]="false"
        [infiniteScrollThrottle]="50" (scrolledUp)="onScrollUp($event)"
        class="h-[90%] overflow-x-hidden overflow-y-auto px-2">
        @for (message of selectedGroup.messages; track $index) {
        <app-message [message]="message"></app-message>
        }
    </div>
    }@else{
    <app-empty-message class="h-full"></app-empty-message>
    } }@else {
    <div class="flex items-center justify-center h-full">
        <mat-spinner color="accent"></mat-spinner>
    </div>
    }

    <app-send-message class="mt-auto"></app-send-message>
</div>