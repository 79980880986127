import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  declarations: [],
  exports: [],
})
export class IconSvgModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry
      .addSvgIcon(
        'search-icon',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/search-icon.svg'
        )
      )
      .addSvgIcon(
        'face-not-found',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/face-not-found.svg'
        )
      )
      .addSvgIcon(
        'mrz-not-found',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/mrz-not-found.svg'
        )
      )
      .addSvgIcon(
        'id-power-false',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/id-power-false.svg'
        )
      )
      .addSvgIcon(
        'transmitting',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/transmitting.svg'
        )
      )
      .addSvgIcon(
        'recording',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/recording.svg'
        )
      )
      .addSvgIcon(
        'liveness',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/liveness.svg'
        )
      )
      .addSvgIcon(
        'filter-icon',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/filter-icon.svg'
        )
      )
      .addSvgIcon(
        'Passport',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/passport.svg'
        )
      )
      .addSvgIcon(
        'IdCard',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/civil-id.svg'
        )
      )
      .addSvgIcon(
        'civil-extract',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/civil-extract.svg'
        )
      )
      .addSvgIcon(
        'driving-license',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/driving-license.svg'
        )
      )
      .addSvgIcon(
        'upload',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/upload.svg'
        )
      )
      .addSvgIcon(
        'reset-signature',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/reset-signaturee.svg'
        )
      )
      .addSvgIcon(
        'hold-steady',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/hold-steady.svg'
        )
      )
      .addSvgIcon(
        'pdf',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/pdf.svg'
        )
      )
      .addSvgIcon(
        'info',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/info.svg'
        )
      )
      .addSvgIcon(
        'Other',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/other.svg'
        )
      )
      .addSvgIcon(
        'green-info',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/green-info.svg'
        )
      )
      .addSvgIcon(
        're-capture',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/re-capture.svg'
        )
      )
      .addSvgIcon(
        'face-capture',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/capture.svg'
        )
      )
      .addSvgIcon(
        'warn-info',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/warn-info.svg'
        )
      )
      .addSvgIcon(
        'view-details',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/view-details.svg'
        )
      )
      .addSvgIcon(
        '404',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/404.svg'
        )
      )
      .addSvgIcon(
        'logo',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/logo.svg'
        )
      )
      .addSvgIcon(
        'upload-image',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/upload-image.svg'
        )
      )
      .addSvgIcon(
        'resize-expand',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/resize-expand.svg'
        )
      )
      .addSvgIcon(
        'process',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/process.svg'
        )
      )
      .addSvgIcon(
        'onRetryMessage',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/on-retry-message.svg'
        )
      )
      .addSvgIcon(
        'brightness',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/brightness.svg'
        )
      )
      .addSvgIcon(
        'ellipse',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/ellipse.svg'
        )
      )
      .addSvgIcon(
        'id-power-true',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/id-power-true.svg'
        )
      )
      .addSvgIcon(
        'blurriness',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/blurriness.svg'
        )
      )
      .addSvgIcon(
        'capture',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/capture.svg'
        )
      )
      .addSvgIcon(
        'reset-document',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/x-button.svg'
        )
      )
      .addSvgIcon(
        'upload-document',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/upload.svg'
        )
      )
      .addSvgIcon(
        'live-capture',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/live-capture.svg'
        )
      )
      .addSvgIcon(
        'edit',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/edit.svg'
        )
      )
      .addSvgIcon(
        'id-card-front',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/id-card-front.svg'
        )
      )
      .addSvgIcon(
        'id-card-back',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/id-card-back.svg'
        )
      )
      .addSvgIcon(
        'id-card-back-not-passed',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/id-card-back-not-passed.svg'
        )
      )
      .addSvgIcon(
        'id-card-back-passed',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/id-card-back-passed.svg'
        )
      )
      .addSvgIcon(
        'id-card-front-not-passed',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/id-card-front-not-passed.svg'
        )
      )
      .addSvgIcon(
        'id-card-front-passed',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/id-card-front-passed.svg'
        )
      )
      .addSvgIcon(
        'passport-phone-label',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/passport-phone-label.svg'
        )
      )
      .addSvgIcon(
        'passport-laptop-label',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/passport-laptop-label.svg'
        )
      )
      .addSvgIcon(
        'face-image-retry',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/face-image-retry.svg'
        )
      )
      .addSvgIcon(
        'motion',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/motion.svg'
        )
      )
      .addSvgIcon(
        'InformativeOnBoarding',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/InformativeOnBoarding.svg'
        )
      )
      .addSvgIcon(
        'Questionnaire',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/Questionnaire.svg'
        )
      )
      .addSvgIcon(
        'LocationSelection',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/LocationSelection.svg'
        )
      )
      .addSvgIcon(
        'IdentificationDocumentCapture',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/IdentificationDocumentCapture.svg'
        )
      )
      .addSvgIcon(
        'FaceImageAcquisition',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/FaceImageAcquisition.svg'
        )
      )
      .addSvgIcon(
        'DocumentCapture',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/DocumentCapture.svg'
        )
      )
      .addSvgIcon(
        'ContextAwareSigning',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/ContextAwareSigning.svg'
        )
      )
      .addSvgIcon(
        'WrapUp',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/WrapUp.svg'
        )
      )
      .addSvgIcon(
        'AssistedDataEntry',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/AssistedDataEntry.svg'
        )
      )
      .addSvgIcon(
        'TermsConditions',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/TermsConditions.svg'
        )
      )
      .addSvgIcon(
        'ManualProcessing',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/ManualProcessing.svg'
        )
      )
      .addSvgIcon(
        'ServiceSelection',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/ServiceSelection.svg'
        )
      )
      .addSvgIcon(
        'mismatch',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/mismatch.svg'
        )
      )
      .addSvgIcon(
        'realId',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/realId.svg'
        )
      )
      .addSvgIcon(
        'Split',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/Split.svg'
        )
      )
      .addSvgIcon(
        'SlotBooking',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/SlotBooking.svg'
        )
      )
      .addSvgIcon(
        'preview-more',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/preview-more.svg'
        )
      )
      .addSvgIcon(
        'preview-less',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/preview-less.svg'
        )
      )
      .addSvgIcon(
        'deleteCode',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/delete-code.svg'
        )
      )
      .addSvgIcon(
        'auto-off',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/auto-off.svg'
        )
      )
      .addSvgIcon(
        'getting-things-ready',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/getting-things-ready.svg'
        )
      ).addSvgIcon(
        'otp',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/svg-icons/otp.svg'
        )
      );
  }
}
